import cn from "classnames"
import { Formik, Form } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import { REVIEW_TYPE, ArtiSender } from "../constants"

import ArtiChatInputArea from "./ArtiChatInputArea"
import ArtiChatMessages from "./ArtiChatMessages"
import ArtiMemberSelectorMessage from "./ArtiMemberSelectorMessage"
import ArtiPerformanceReviewOptionsSelector from "./ArtiPerformanceReviewOptionsSelector"
import ArtiPerformanceReviewSelectorMessage from "./ArtiPerformanceReviewSelectorMessage"
import ArtiTeamMemberOptedOutMessage from "./ArtiTeamMemberOptedOutMessage"

import { CheckIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import View from "ui/View"

const ArtiChatTypePerformanceReview = styled(function ArtiChatTypePerformanceReview({
  user,
  team,
  llm,
  selectedChatMember,
  teamMembers,
  chatMemberId,
  onChatMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
  showTeamSummary,
  showPerformanceReview,
  chatTypeSelected,
  isFetchingHistorySession,
  messages,
  setMessages,
  loading,
  historyExchangeId,
  chatInputRef,
  artiTourPopularTopicsMenuOpen,
  isComponentMounted,
  onSubmit,
  reviewType,
  setReviewType,
  performanceReviewOptions,
  onPerformanceReviewOptionsChange,
  sessionStartedAt,
  startExchangeId,
  prevExchangeId,
  className,
}) {
  const memberOptedOut = selectedChatMember?.arti_disabled
  const [showSelfReviewFinishButtons, setShowSelfReviewFinishButtons] = useState(
    reviewType === REVIEW_TYPE.SELF_REVIEW && messages.length >= 2
  )
  const showInputField = !showSelfReviewFinishButtons

  function updateNewBotMessage({ newMessageText }) {
    setMessages([
      ...messages,
      {
        text: newMessageText,
        sender: ArtiSender.BOT,
      },
    ])
  }
  function onSelfReviewFinish({ value, formik }) {
    setReviewType(value)
    setShowSelfReviewFinishButtons(false)
    onSubmit(
      {
        message: "I'm done. Let's move on.",
        reviewType: value,
      },
      formik
    )
  }

  function onSelfReviewContinue() {
    setShowSelfReviewFinishButtons(false)
    updateNewBotMessage({ newMessageText: "Got it. What else would you like to add?" })
  }

  const onFormSubmit = async (values, formik) => {
    await onSubmit?.(values, formik)
    setShowSelfReviewFinishButtons(reviewType === REVIEW_TYPE.SELF_REVIEW)
  }

  return (
    <div className={className}>
      <ArtiMemberSelectorMessage
        user={user}
        team={team}
        llm={llm}
        selectedChatMember={selectedChatMember}
        teamMembers={teamMembers}
        chatMemberId={chatMemberId}
        onChatMemberChange={onChatMemberChange}
        memberIdTeamNameMap={memberIdTeamNameMap}
        onStartNewConversation={onStartNewConversation}
        showTeamSummary={showTeamSummary}
        showPerformanceReview={showPerformanceReview}
        chatTypeSelected={chatTypeSelected}
      />
      {!!selectedChatMember && (
        <ArtiPerformanceReviewSelectorMessage
          user={user}
          team={team}
          teamMember={selectedChatMember}
          showPerformanceReview={showPerformanceReview}
          chatTypeSelected={chatTypeSelected}
          reviewType={reviewType}
          onPerformanceReviewOptionsChange={onPerformanceReviewOptionsChange}
          performanceReviewOptions={performanceReviewOptions}
          onSubmit={onFormSubmit}
          llm={llm}
          sessionStartedAt={sessionStartedAt}
          startExchangeId={startExchangeId}
          prevExchangeId={prevExchangeId}
        />
      )}
      {!!performanceReviewOptions && (
        <ArtiPerformanceReviewOptionsSelector
          user={user}
          teamMembers={teamMembers}
          teamMember={selectedChatMember}
          performanceReviewOptions={performanceReviewOptions}
        />
      )}
      {!memberOptedOut ? (
        !!selectedChatMember &&
        !!performanceReviewOptions && (
          <div
            className={cn("fadeable conversation-container", {
              hidden: !selectedChatMember || isFetchingHistorySession,
            })}
          >
            <ArtiChatMessages
              messages={messages}
              user={user}
              team={team}
              loading={loading}
              llm={llm}
              historyExchangeId={historyExchangeId}
              chatInputRef={chatInputRef}
              chatTypeSelected={chatTypeSelected}
            />
            {!!showSelfReviewFinishButtons && (
              <Formik initialValues={{ message: "", popularTopic: null }}>
                {(formik) => (
                  <Form className={cn({ "non-interactive-and-faded": loading })}>
                    <div className="text-right mb-xxs text-semi-bold full-width">you</div>
                    <View $alignItems="center" $flexDirectionMobile="column">
                      <div className="message user query-editor full-width mb-medium-mobile-only">
                        <View className="p-small" $flexDirectionMobile="column" $gapMobile="12px">
                          <Button
                            className="fit-content p-xs text-gray-9 arti-review-type-button"
                            onClick={() => onSelfReviewFinish({ value: REVIEW_TYPE.MGR_REVIEW, formik })}
                          >
                            <CheckIcon />
                            Yes! I'm finished
                          </Button>
                          <Button
                            className="fit-content p-xs bg-orange-3 border-gray-3 arti-review-type-button"
                            onClick={() => onSelfReviewContinue()}
                          >
                            No, I have more to add
                          </Button>
                        </View>
                      </div>
                    </View>
                  </Form>
                )}
              </Formik>
            )}
            <ArtiChatInputArea
              loading={loading}
              isComponentMounted={isComponentMounted}
              onSubmit={onFormSubmit}
              chatInputRef={chatInputRef}
              messages={messages}
              setMessages={setMessages}
              user={user}
              team={team}
              selectedChatMember={selectedChatMember}
              artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
              chatTypeSelected={chatTypeSelected}
              reviewType={reviewType}
              setReviewType={setReviewType}
              showInputField={showInputField}
              className="mb-medium"
            />
          </div>
        )
      ) : (
        <ArtiTeamMemberOptedOutMessage selectedChatMember={selectedChatMember} />
      )}
    </div>
  )
})`
  .arti-review-type-button {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;

    &:hover,
    &:focus-visible {
      border-radius: 4px;
      background-color: #fbe8ca;
      box-shadow: 0px 2px 4px 0px rgba(255, 211, 89, 0.75);
    }
  }
`

export default ArtiChatTypePerformanceReview
