import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useSessionExerciseInstances } from "resources/exercise"
import { useKitParticipants } from "resources/monthly_kit"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useSelectedUser } from "ui/SelectedUserContext"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const GuessingGameCorrectUserBubbles = styled(function GuessingGameCorrectUserBubbles({ identifier, className }) {
  const { kitInstance, team } = useKitSession()
  const { selectedUser } = useSelectedUser()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: kitParticipants, isInitialLoading: isInitialLoadingKitParticipants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { data: exerciseInstances, isInitialLoading: isInitialLoadingExerciseInstances } = useSessionExerciseInstances(
    kitInstance?.id,
    {
      sessionRealtimeUpdates,
      refetchInterval: 30000,
    }
  )
  const [correctUsers, setCorrectUsers] = useState([])

  useEffectAfterChange(() => {
    if (!!selectedUser && !isInitialLoadingExerciseInstances && !isInitialLoadingKitParticipants) {
      const correctUsers = exerciseInstances
        ?.map((exerciseInstance) => {
          const userGuesses = getExerciseAnswer(exerciseInstance, identifier)
          const currentUserAnswer = userGuesses?.find((userGuess) => userGuess["correctAnswer"] === selectedUser.id)
          if (!!currentUserAnswer && Number(currentUserAnswer["userAnswer"]) === selectedUser.id) {
            const correctParticipant = kitParticipants.find(
              (participant) => participant.id === exerciseInstance.user_id
            )
            // Debugging code to check if the correctParticipant is being generated correctly,
            // will be removed TODO @shivam-risingteam
            if (!correctParticipant) {
              Sentry.captureMessage(
                `Exercise Instance user id ${exerciseInstance.user_id} not found in participants list ${kitParticipants.map((user) => user.id)}`
              )
              return null
            }
            return correctParticipant?.short_name
          }
          return null
        })
        ?.filter((user) => user != null)
      setCorrectUsers(correctUsers)
    }
  }, [
    exerciseInstances,
    kitParticipants,
    identifier,
    selectedUser,
    isInitialLoadingExerciseInstances,
    isInitialLoadingKitParticipants,
  ])

  if (isInitialLoadingExerciseInstances || isInitialLoadingKitParticipants) {
    return <Loading />
  }

  return (
    <div className={cn(className, "p-medium")}>
      <p className="mb-xs text-semi-bold">Who guessed it:</p>
      {correctUsers.length > 0 ? (
        <View $alignItems="center" $gap="4px" $flexWrap="wrap">
          <p className="text-small text-capitalize">{correctUsers.join(", ")}</p>
        </View>
      ) : (
        <View>
          <p>No one!</p>
        </View>
      )}
    </div>
  )
})``

export default GuessingGameCorrectUserBubbles
