import cn from "classnames"
import { Form, Formik } from "formik"
import { useNavigate } from "react-router"
import Sticky from "react-stickynode"
import { styled } from "styled-components"

import { LLMName, LLMType } from "../constants"

import { ArtiTourStepNames } from "./ArtiTour"

import { DEFAULT_TOUR_HASH } from "components/Tour"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { PlusIcon, QuestionIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import PageTitle from "ui/PageTitle"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { ZIndex } from "ui/z-index"
import { useHasTeamFeature } from "utils/team"

const ArtiChatHeader = styled(function ArtiChatHeader({
  onLLMChange,
  llm,
  availableLLMs,
  team,
  startNewConversation,
  chatTypeSelected,
  showTeamSummary,
  showPerformanceReview,
  className,
}) {
  const navigate = useNavigate()

  const { enabled: showArtiHelp } = useHasTeamFeature(team, SHARED_FLAGS.ARTI_HELP)
  const { enabled: showLLMSelect } = useHasTeamFeature(team, SHARED_FLAGS.ARTI_SHOW_LLM_SELECT)

  return (
    <div className={className}>
      <PageTitle>aRTi</PageTitle>
      <Sticky enabled={true} top={0} innerZ={ZIndex.Sticky} className="arti-controls-sticky-wrapper">
        <View $flexDirection="column" $alignItems="flex-end" className="arti-controls pt-medium">
          <View>
            <h1 className="text-semi-bold mb-small-mobile-never mb-small-mobile-only flex-grow-1">aRTi</h1>
            <View $flexDirection="row-reverse" $flexDirectionMobile="column" $alignItemsMobile="flex-end">
              {!!showArtiHelp && !showTeamSummary && !showPerformanceReview && (
                <Button
                  className="ml-medium mt-xs mr-none arti-help-button"
                  onClick={() => {
                    navigate(`/arti?team_id=${team.id}#${DEFAULT_TOUR_HASH}`)
                    // NOTE: Don't include aRTi exchange ID here because the tour
                    // needs chat UI to be reset so it can start at member selection.
                    // We include team_id because it prevents a redirect / UI refresh.
                  }}
                >
                  <Tooltip top title="Help">
                    <QuestionIcon className="ml-small" />
                  </Tooltip>
                </Button>
              )}
              <Button
                className={cn("mt-xs mr-none tertiary", { hidden: chatTypeSelected == null })}
                onClick={startNewConversation}
                data-testid="arti-new-conversation"
                data-arti-tour-step={ArtiTourStepNames.NewConversation}
              >
                <PlusIcon className="ml-small" />
                New conversation
              </Button>
              {!!showLLMSelect && (
                <Formik initialValues={{ llm }} enableReinitialize>
                  <Form
                    className="fit-content mr-large-mobile-never mt-xxs-mobile-never my-medium-mobile-only"
                    data-testid="arti-llm-select"
                  >
                    <AdvancedSelectField
                      name="llm"
                      onChange={onLLMChange}
                      options={Object.keys(availableLLMs).map((key) => ({
                        value: LLMType[key],
                        label: LLMName[key],
                      }))}
                    />
                  </Form>
                </Formik>
              )}
            </View>
          </View>
        </View>
      </Sticky>
    </div>
  )
})`
  .arti-controls-sticky-wrapper {
    .arti-controls {
      position: relative;
      background-color: var(--fg);
      width: calc(100% + 24px * 2);
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;

      @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
        width: calc(100% + 48px * 2);
        margin-left: -48px;
        margin-right: -48px;
        padding-left: 48px;
        padding-right: 48px;
      }
    }

    &.active .arti-controls {
      box-shadow: 0 4px 4px -4px rgb(35 35 35 / 20%);
    }
  }

  .arti-help-button {
    color: var(--gray-9);
    background: var(--gray-3);
    border: none;
    box-shadow: none;
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    .arti-help-button {
      padding: 6px;
      width: 19px;
      height: 19px;
      font-size: 12px;
      position: absolute;
      top: -2px;
      right: 4px;

      svg {
        margin: 0;
      }
    }
  }
`

export default ArtiChatHeader
