import cn from "classnames"
import { Form, Formik } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import { PERFORMANCE_REVIEW_OPTIONS } from "../constants"

import ArtiUpload from "./ArtiUpload"

import { CommentIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import View from "ui/View"

const ArtiPerformanceReviewSelectField = styled(function ArtiPerformanceReviewSelectField({
  onPerformanceReviewOptionsChange,
  performanceReviewOptions,
  onSubmit,
  llm,
  sessionStartedAt,
  startExchangeId,
  prevExchangeId,
  chatTypeSelected,
  reviewType,
  team,
  selectedChatMember,
  className,
}) {
  const [isUploading, setIsUploading] = useState(false)
  return (
    <Formik initialValues={{ performanceReviewOptions }} enableReinitialize>
      <Form className={cn(className, "pl-small space-y-medium fadeable", { "non-interactive-and-faded": isUploading })}>
        <View $flexDirection="row" $flexDirectionMobile="column" $justifyContent="flex-start" $gap="12px">
          <View
            $gap="8px"
            className={cn("arti-select-new-conversation cursor-pointer p-xs", {
              disabled: !!performanceReviewOptions,
            })}
            $justifyContent="center"
            $alignItems="center"
            $width="max-content"
          >
            <ArtiUpload
              onSubmit={onSubmit}
              onPerformanceReviewOptionsChange={onPerformanceReviewOptionsChange}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              llm={llm}
              sessionStartedAt={sessionStartedAt}
              startExchangeId={startExchangeId}
              prevExchangeId={prevExchangeId}
              chatTypeSelected={chatTypeSelected}
              reviewType={reviewType}
              team={team}
              selectedChatMember={selectedChatMember}
            />
          </View>
          <View>
            <Button
              as="label"
              className="arti-select-new-conversation p-xs"
              onClick={() => onPerformanceReviewOptionsChange({ value: PERFORMANCE_REVIEW_OPTIONS.CHAT_REVIEW })}
              disabled={!!performanceReviewOptions}
            >
              <CommentIcon />
              Ask me questions
            </Button>
          </View>
        </View>
      </Form>
    </Formik>
  )
})`
  .arti-select-new-conversation {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;

    &:hover,
    &:focus-visible {
      border-radius: 4px;
      background-color: #fbe8ca;
      box-shadow: 0px 2px 4px 0px rgba(255, 211, 89, 0.75);
    }
  }
  input[type="file"] {
    display: none;
  }
`

export default ArtiPerformanceReviewSelectField
