import cn from "classnames"
import { Form, Formik } from "formik"
import { range, zip } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import EmailField from "forms/fields/EmailField"
import ValidatedField from "forms/fields/ValidatedField"
import FormMessage from "forms/FormMessage"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import { useAdminAccounts, useAccountTeams, useInviteTeamLeads } from "resources/billing"
import Button from "ui/Button"
import Loading from "ui/Loading"
import SubmitButton from "ui/SubmitButton"
import { colors } from "ui/theme"
import { formatTimestampAsDate } from "utils/date"

const InviteTeamLeadStepComponent = () => {
  const { data: accounts } = useAdminAccounts()
  const account = accounts?.[0] ?? null // Setup applies to first admin account

  if (!account) {
    return <Loading />
  }

  return (
    <div>
      <h3 className="mb-medium">Invite team leads</h3>
      <p className="mb-small">
        Rising Team kits were designed to be run by any team in your organization. To enable others to run Rising Team
        sessions, enter their emails below. If you don’t want/need to invite any team leads, go to the next step. In the
        future, you can add additional team leads from the Admin tab.
      </p>
      <InviteTeamLeadsForm account={account} />
    </div>
  )
}

const InviteTeamLeadsForm = styled(function InviteTeamLeadsForm({ className, account }) {
  const { mutateAsync: inviteTeamLeads } = useInviteTeamLeads(account?.id)
  const { data: teams } = useAccountTeams(account?.id)
  const [numExtraFields, setNumExtraFields] = useState(3)
  const [sentFieldNames, setSentFieldNames] = useState([])
  const [sentLeadEmails, setSentLeadEmails] = useState([])

  if (!Array.isArray(teams)) {
    return <Loading />
  }

  function getTeamLead(team) {
    return team.members.find(({ id }) => id === team.team_lead_id) ?? null
  }
  function getTeamLeadEmail(team) {
    return getTeamLead(team)?.email ?? null
  }

  // Get unique list of team lead emails for existing teams, sorted alphabetically:
  const existingLeadEmails = [...new Set(teams.map(getTeamLeadEmail))]
    .sort()
    .filter((email) => !sentLeadEmails.includes(email))
  // filter here to avoid the list shifting down when a new lead is invited

  const existingLeadFieldNames = existingLeadEmails.map(
    (email, idx) => `email_existing_lead_${idx + 1}`
    // Existing-lead field values are never submitted to the backend, so the field
    // names don't really matter; they just need to be unique (amongst all fields).
  )

  // Existing-lead fields are permanently disabled, displayed above new-lead fields.
  // They are shown so that the user knows which leads have already been invited.
  const existingLeadFields = zip(existingLeadFieldNames, existingLeadEmails).map(([fieldName, email]) => ({
    fieldName,
    value: email,
  }))

  // Build array of field names and values that will be used to construct the form:
  const emailFields = [
    ...existingLeadFields,
    ...range(numExtraFields).map((idx) => ({
      fieldName: `email_${idx + 1}`,
      value: "",
    })),
  ]

  // Build Formik initial values and schema:
  const initialValues = Object.fromEntries(emailFields.map(({ fieldName, value }) => [fieldName, value]))
  const schema = Yup.object().shape(
    Object.fromEntries(
      emailFields.map(({ fieldName }) => [fieldName, Yup.string().email("Please enter a valid email.")])
    )
  )

  const leadInvitedAtMap = new Map(teams.map(getTeamLead).map((lead) => [lead.email, lead.invited_at]))

  const onSubmit = handleErrors(async (values, { setErrors }) => {
    // Construct data to be sent to the server, which
    // - excludes all fields for existing leads
    // - excludes all fields that were already submitted
    // - excludes all fields with empty values
    // - trims any surrounding whitespace from field values
    const teamLeadValues = Object.fromEntries(
      Object.entries(values)
        .filter(([fieldName]) => !existingLeadFieldNames.includes(fieldName))
        .filter(([fieldName]) => !sentFieldNames.includes(fieldName))
        .map(([fieldName, value]) => [fieldName, value?.trim() ?? ""])
        .filter(([_fieldName, value]) => value?.length)
    )
    const newInviteValues = Object.fromEntries(
      Object.entries(teamLeadValues).filter(
        ([_fieldName, email]) => !existingLeadEmails.includes(email) && !sentLeadEmails.includes(email)
      )
    )
    // Don't send emails to server which were already invited; instead show error:
    const duplicateInviteValues = Object.fromEntries(
      Object.entries(teamLeadValues).filter(
        ([_fieldName, email]) => existingLeadEmails.includes(email) || sentLeadEmails.includes(email)
      )
    )
    setErrors(
      Object.fromEntries(
        Object.keys(duplicateInviteValues).map((fieldName) => {
          const invitedAt = leadInvitedAtMap.get(values[fieldName])
          const invitedAtMsg = invitedAt && `Team lead invited on ${formatTimestampAsDate(invitedAt)}`
          const alreadyInvitedMsg = "Team lead already invited"
          return [fieldName, invitedAt ? invitedAtMsg : alreadyInvitedMsg]
        })
      )
    )
    await inviteTeamLeads(newInviteValues)
    setSentFieldNames([...sentFieldNames, ...Object.keys(newInviteValues)])
    setSentLeadEmails([...sentLeadEmails, ...Object.values(newInviteValues)])
  })

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setValues, setErrors }) => (
        <Form name="invite-leads-form" className={cn(className, "mt-large")}>
          <div className="text-semi-bold">Enter team email addresses below:</div>
          {emailFields.map(({ fieldName }) => (
            <ValidatedField
              className="mt-small full-width"
              base={EmailField}
              key={fieldName}
              name={fieldName}
              value={values[fieldName]}
              onClear={() => setValues({ [fieldName]: "" })}
              onClick={() => setErrors({})}
              disabled={existingLeadFieldNames.includes(fieldName) || sentFieldNames.includes(fieldName)}
              disableAutoComplete
            />
          ))}
          <Button
            color={colors.risingBlue}
            className="link-semi-bold mt-small"
            onClick={() => setNumExtraFields(numExtraFields + 1)}
          >
            + Add another
          </Button>
          <div>
            <SubmitButton label="Send invites" className="tertiary mt-large" />
          </div>
          <FormMessage />
        </Form>
      )}
    </Formik>
  )
})`
  .field-message {
    color: var(--gray-8); // use gray for field errors instead of red
  }
`

export default InviteTeamLeadStepComponent
