import cn from "classnames"
import { styled } from "styled-components"

import View from "ui/View"

const RankOrderFieldTopNHighlight = styled(function RankOrderFieldTopNHighlight({
  identifier,
  count,
  exerciseInstance,
  highlightDescriptionBlocks = null,
  nonHighlightDescriptionBlocks = null,
  emptyMessage = "No options available",
  className,
}) {
  const values = exerciseInstance?.answers?.find((answer) => answer.identifier === identifier)?.data ?? []
  if (!values.length) {
    return <div className={cn("text-italic", className)}>{emptyMessage}</div>
  }

  const highlightedValues = values.slice(0, count)
  const nonHighlightedValues = values.slice(count)

  return (
    <div className={className}>
      {!!highlightDescriptionBlocks && <div className="mb-medium">{highlightDescriptionBlocks}</div>}
      <div className="highlighted-values">
        {highlightedValues.map((value, index) => {
          const itemNumber = index + 1
          return (
            <RankOrderItem key={index} itemNumber={itemNumber} highlighted={true} className="border-top">
              {value}
            </RankOrderItem>
          )
        })}
      </div>
      {!!nonHighlightDescriptionBlocks && <div className="my-medium">{nonHighlightDescriptionBlocks}</div>}
      <div className="non-highlighted-values">
        {nonHighlightedValues.map((value, index) => {
          const itemNumber = count + index + 1
          return (
            <RankOrderItem key={index} itemNumber={itemNumber} highlighted={false} className="border-top">
              {value}
            </RankOrderItem>
          )
        })}
      </div>
    </div>
  )
})`
  .highlighted-values {
    border-bottom: 1px dashed var(--rising-orange);
  }
`

const RankOrderItem = ({ itemNumber, children, highlighted, className }) => (
  <View className={cn(className, "py-medium px-xs", { "bg-gray-5 text-gray-7": !highlighted })}>
    <div className={cn("mr-medium", { "text-rising-orange": !!highlighted })}>{itemNumber}</div>
    <div>{children}</div>
  </View>
)

export default RankOrderFieldTopNHighlight
