import cn from "classnames"

import BulletedList from "ui/BulletedList"

const RankOrderFieldTopNList = ({
  identifier,
  count,
  exerciseInstance,
  inverse,
  emptyMessage = "No items",
  className,
}) => {
  const values = exerciseInstance?.answers?.find((answer) => answer.identifier === identifier)?.data ?? []

  if (!values.length) {
    return <p className={cn("text-italic text-gray-8", className)}>{emptyMessage}</p>
  }

  const selectedValues = inverse ? values.slice(count) : values.slice(0, count)

  return (
    <BulletedList className={className}>
      {selectedValues.map((value, index) => (
        <li key={index}>{value}</li>
      ))}
    </BulletedList>
  )
}

export default RankOrderFieldTopNList
