import * as Sentry from "@sentry/browser"
import { useField } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import { fetchS3Post } from "api"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import FieldMessage from "forms/FieldMessage"
import { CameraIcon, CloudArrowUpIcon } from "icons/FontAwesomeIcons"
import { getAWSImageUploadURL } from "resources/users"
import Button from "ui/Button"
import useFeatures, { SHARED_FLAGS, FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import View from "ui/View"
import {
  resizeImage,
  getImageExtension,
  SUPPORTED_RESIZING_IMAGE_TYPES,
  SUPPORTED_IMAGE_TYPES,
  ACCEPTABLE_UPLOAD_TYPES,
} from "utils/image"
import { useHasTeamFeature } from "utils/team"

const MAX_IMAGE_SIZE_MB = 8

const ExerciseImageField = ({ className, name, saveOnChange }) => {
  const { team } = useKitSession()
  const [field, _meta, helpers] = useField(name)
  const [imageUrl, setImageUrl] = useState(field.value)
  const [isUploading, setIsUploading] = useState(false)
  const { enabled: imageResizing } = useHasTeamFeature(team, SHARED_FLAGS.IMAGE_RESIZING)
  const { [FLAGS.RTDEV_UPLOAD_IMAGE_DIRECTLY_AWS]: directlyUploadImage } = useFeatures()

  const onChange = async (evt) => {
    const uploadImage = evt.target.files[0]
    if (!uploadImage) {
      return
    }

    setIsUploading(true)

    // For some reason Formik doesn't automatically set touched on image fields,
    // but it's needed for error messages to display
    helpers.setTouched(true, false)

    const imageExtension = getImageExtension({ file: uploadImage })
    if (!SUPPORTED_IMAGE_TYPES.has(imageExtension)) {
      helpers.setError(
        `Oops, this file type is not supported. Image type must be one of: ${[...SUPPORTED_IMAGE_TYPES].join(", ")}`
      )
      setIsUploading(false)
      return
    }
    const image =
      imageResizing && SUPPORTED_RESIZING_IMAGE_TYPES.includes(imageExtension)
        ? await resizeImage({ file: uploadImage })
        : uploadImage

    if (image.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      helpers.setError(`Oops, the image size must be under ${MAX_IMAGE_SIZE_MB}mb.`)
      setIsUploading(false)
      return
    }

    const onSuccess = (exerciseInstance) => {
      const answer = exerciseInstance.answers.find((answer) => answer.identifier === name)
      helpers.setValue(answer.data)
      setImageUrl(answer.data)
      setIsUploading(false)
      helpers.setTouched(false)
    }
    const onError = ({ error, isAWSUploadError = false }) => {
      Sentry.captureException(error)
      const errorMessage = isAWSUploadError
        ? getAWSUploadErrorMessage()
        : getResponseErrorMessage(error.response, directlyUploadImage)
      helpers.setError(errorMessage)
      setIsUploading(false)
    }

    if (directlyUploadImage) {
      const { aws_presigned_data: presignedUploadData, object_key: objectKeyAWS } = await getAWSImageUploadURL({
        userId: "me",
        imageExtension,
      })
      fetchS3Post(presignedUploadData, image)
        .then((response) => {
          if (response.ok) {
            saveOnChange && saveOnChange(name, null, { objectKeyAWS, onSuccess, onError })
          } else {
            onError({ error: response, isAWSUploadError: true })
          }
        })
        .catch((error) => {
          onError({ error, isAWSUploadError: true })
        })
    } else {
      saveOnChange && saveOnChange(name, null, { image, onSuccess, onError })
    }
  }

  if (isUploading) {
    return (
      <View className={className} $width="377px" $height="377px">
        <Loading />
      </View>
    )
  }

  return (
    <View className={className}>
      <View $flexDirection="column" $justifyContent="center" $alignItemsMobile="center">
        {!imageUrl ? (
          <View className="photo-dropzone border-radius-small" $justifyContent="center" $alignItems="center">
            <View
              className="text-blue-2 text-center"
              $flexDirection="column"
              $justifyContent="center"
              $alignItems="center"
            >
              <CloudArrowUpIcon className="fa-3x" />
              <p className="text-bold mb-small">Drag & drop your photo here or</p>
              <Button as="label" className="tertiary cursor-pointer" htmlFor="exercise_answer_image">
                Browse Files
              </Button>
            </View>
          </View>
        ) : (
          <>
            <View className="exercise-picture">
              <View $flexDirection="column">
                <img src={imageUrl} alt="Uploaded file" />
              </View>
              <label
                htmlFor="exercise_answer_image"
                className="exercise-picture-button text-gray-9 border-radius-medium px-small"
              >
                <CameraIcon className="fa-lg cursor-pointer" />
              </label>
            </View>
          </>
        )}
        <div>
          <FieldMessage name={name} data-cy={`field-message-${name}`} />
          <div className="input-image-wrapper">
            <input className="input-image" type="file" accept={ACCEPTABLE_UPLOAD_TYPES} onChange={onChange}></input>
          </div>
        </div>
      </View>
    </View>
  )
}

const getResponseErrorMessage = (response, directlyUploadImage) => {
  if (!directlyUploadImage && response?.status === 400 && response?.data?.image?.length) {
    return response.data.image[0]
  }

  return "Oops, please try again."
}

const getAWSUploadErrorMessage = () => "Network error. Please try again."

export default styled(ExerciseImageField)`
  overflow: hidden;
  // We need overflow:hidden here because we set a fixed height below, which
  // can cause the image input to overlap elements above it on the page when
  // small images are uploaded.

  .photo-dropzone {
    max-width: 377px;
    height: 377px;

    background: rgba(10, 132, 255, 0.1);
    border: 1px dashed var(--rising-blue);
  }

  label[for="exercise_answer_image"] .field-message {
    width: 100%;
    text-align: center;
  }

  .input-image-wrapper {
    width: 377px;
    height: 377px;
    margin-top: -377px;
    z-index: var(--z-above-zero);
  }

  .input-image {
    height: 100%;
    width: 100%;
    opacity: 0%;
  }

  .exercise-picture {
    position: relative;
    width: auto;
    max-width: 377px;

    img {
      object-fit: contain;
      border-radius: 8px;
      max-width: 377px;
      max-height: 377px;
    }

    &-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: var(--fg);
      box-shadow: var(--lift-4);
    }
  }
`
