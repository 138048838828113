import { Formik } from "formik"
import { useState } from "react"

import ExerciseContext from "domains/Exercise/ExerciseContext"
import ExerciseStep from "domains/Exercise/ExerciseStep"
import { useExerciseDefinition } from "resources/exercise"
import { useUser } from "resources/users"
import BackButton from "ui/BackButton"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import NextButton from "ui/NextButton"
import StepProgressBar from "ui/StepProgressBar"
import View from "ui/View"

const ExerciseStepsInMemoryBonus = ({ kit, kitInstance, className }) => {
  const { data: user } = useUser({ userId: "me" })
  const [stepIndex, setStepIndex] = useState(0)
  const [exerciseInstance, setExerciseInstance] = useState()
  const { data: exerciseDefinition } = useExerciseDefinition({
    teamId: kitInstance.team_id,
    slug: kit.exercise.slug,
    version: kit.exercise.version,
  })
  useEffectAfterChange(() => {
    if (!exerciseInstance && exerciseDefinition && user) {
      setExerciseInstance({ definition: exerciseDefinition, user_id: user.id })
    }
  }, [exerciseDefinition, exerciseInstance, user])

  if (!exerciseDefinition || !exerciseInstance) {
    return null
  }

  const bonusSteps = exerciseDefinition.bonus_exercise_steps
  const allComponents = bonusSteps.flatMap((step) => step.components).filter(Boolean)
  const initialValues = allComponents.reduce((acc, component) => {
    const { identifier, initialValue } = component
    return { ...acc, [identifier]: initialValue }
  }, {})

  const step = bonusSteps[stepIndex]
  const onBreadcrumbClick = (step, idx) => {
    setStepIndex(idx)
  }
  const onBackButtonClick = () => {
    setStepIndex(stepIndex - 1)
  }
  const onNextButtonClick = () => {
    setStepIndex(stepIndex + 1)
  }

  const breadcrumbs = <StepProgressBar currentStep={step} allSteps={bonusSteps} onStepClick={onBreadcrumbClick} />
  const hasBackButton = stepIndex > 0
  const hasNextButton = stepIndex < bonusSteps.length - 1

  const saveOnChange = (name, value) => {
    const answers = exerciseInstance.answers ?? []
    const filteredAnswers = answers.filter((answer) => answer.identifier !== name)
    const newAnswer = { identifier: name, data: value }
    const newAnswers = [...filteredAnswers, newAnswer]
    const newExerciseInstance = { ...exerciseInstance, answers: newAnswers }
    setExerciseInstance(newExerciseInstance)
  }

  return (
    <ExerciseContext.Provider value={{ exerciseInstance }}>
      <Formik initialValues={initialValues}>
        <div className={className}>
          <div className="mb-medium">{breadcrumbs}</div>
          <ExerciseStep kit={kit} is_standalone={true} step={step} saveOnChange={saveOnChange} />
          <View className="pb-medium mt-xxxxl" $justifyContent={hasBackButton ? "center" : "flex-start"}>
            {!!hasBackButton && <BackButton className="secondary" onClick={onBackButtonClick} />}
            {!!hasNextButton && <NextButton onClick={onNextButtonClick} />}
          </View>
        </div>
      </Formik>
    </ExerciseContext.Provider>
  )
}

export default ExerciseStepsInMemoryBonus
