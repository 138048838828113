import { styled } from "styled-components"

import ChoicesField, { IconChoiceInput } from "forms/fields/ChoicesField"
import useWindowSize from "ui/hooks/useWindowSize"

const ExerciseGridRadioGroupField = styled(function ExerciseGridRadioGroupField({
  name,
  options,
  columns: _columns = 4, // only used in css
  max_width_column = false,
  option_exercise_field_save_as_text: _option_exercise_field_save_as_text,
  // NOTE: We must eliminate option_exercise_field_save_as_text from props before they're
  // passed down to a base DOM element (input in this case) because that causes errors.
  // TODO @evnp Look into how we might resolve this more universally and reliably.
  ...props
}) {
  const { isMobileOrSmaller } = useWindowSize()
  const isFullWidthMobile = !!(max_width_column && isMobileOrSmaller)
  const displayOptions = isFullWidthMobile ? [...options].reverse() : options
  return (
    <ChoicesField
      name={`${name}.value`}
      type="radio"
      options={displayOptions}
      component={IconChoiceInput}
      isFullWidthMobile={isFullWidthMobile}
      {...(isFullWidthMobile ? { maxWidth: "100%" } : {})}
      {...props}
    />
  )
})`
  width: 100%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, minmax(0, 176px));
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.desktopMin}) {
    grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 176px));
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: 1fr;
  }
`

export default ExerciseGridRadioGroupField
