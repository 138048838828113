import Table from "components/Table"
import RemoveButton from "domains/Admin/RemoveButton"
import ResendInviteLink from "domains/Admin/ResendInviteLink"
import { useResendTeamMemberInvite, useRemoveTeamMember } from "resources/teams"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import View from "ui/View"
import { isMemberColead, useHasTeamFeature, useTeamInfo } from "utils/team"

const TeamMembersTable = ({ className, team, query, isInModal }) => {
  const { mutateAsync: resendTeamMemberInvite } = useResendTeamMemberInvite({ teamId: team.id })
  const { mutateAsync: removeTeamMember } = useRemoveTeamMember({ teamId: team.id, accountId: team.account_id })
  const { enabled: showColeads } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_COLEADS)

  const { getNonLeadMembers, getUserNameOrEmail, isMemberInvitePending } = useTeamInfo()
  const members = getNonLeadMembers(team)

  return (
    <Table
      className={className}
      query={query}
      rows={members}
      empty="No team members yet."
      notFound="No matching team members found."
      defaultSort={[{ id: "status", desc: true }, { id: "name" }]}
      maxHeightInRows={isInModal ? 4 : null}
      columns={[
        {
          id: "name",
          Header: `Team members (${members.length})`,
          searchable: true,
          accessor: getUserNameOrEmail,
          tooltip: (user) => (user.name ? user.email : null),
          title: (user) => (user.name ? null : `No user name set for ${user.email}`),
        },
        {
          id: "status",
          Header: "Invite status",
          reverseHeaderSortArrow: true,
          accessor: (user) => (isMemberInvitePending(user) ? "Not yet accepted" : "Accepted"),
        },
        {
          id: "controls",
          disableSortBy: true,
          accessor: (user) =>
            isMemberInvitePending(user) ? (
              <View>
                <ResendInviteLink
                  text="Resend invite"
                  resendInvite={resendTeamMemberInvite}
                  resendInviteArgs={[user.id]}
                />
                <RemoveButton
                  userId={user.id}
                  buttonText="Cancel invite"
                  confirmTitle="Cancel invite?"
                  confirmBody="Are you sure you want to cancel the team invite?"
                  confirmText="Yes"
                  cancelText="No"
                  onConfirm={() => removeTeamMember(user.id)}
                />
              </View>
            ) : (
              <View $alignItems="center" $justifyContent="space-between">
                <RemoveButton
                  userId={user.id}
                  buttonText="Remove team member"
                  confirmTitle="Remove member?"
                  confirmBody="Are you sure you want to remove this member from the team?"
                  confirmText="Remove"
                  cancelText="Cancel"
                  onConfirm={() => removeTeamMember(user.id)}
                />
                {!!showColeads &&
                  (isMemberColead(user, team) ? <View>Member is colead</View> : <View>Member is not colead</View>)}
              </View>
            ),
        },
      ]}
    />
  )
}

export default TeamMembersTable
