import cn from "classnames"
import { get, mapValues } from "lodash-es"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import BulletedList from "ui/BulletedList"

const RepeatingGroupTextFieldMatchingListGroups = ({
  identifier,
  textIdentifier,
  selectIdentifiers = [],
  matchValues = {},
  exerciseInstance,
  valueFieldIdentifier,
  maxListCounts = {},
  className,
}) => {
  const answerList = getExerciseAnswer(exerciseInstance, identifier) ?? []
  const answerGroups = mapValues(matchValues, (matchValueList = []) =>
    answerList
      .filter((answer) => {
        const valueFieldAnswer = get(answer, [valueFieldIdentifier], null)
        const value = get(valueFieldAnswer, ["value"], valueFieldAnswer)
        return matchValueList.includes(value)
      })
      .map((answer) => {
        const textAnswer = get(answer, [textIdentifier], null)
        return get(textAnswer, ["value"], textAnswer)
      })
      .filter(Boolean)
  )

  return (
    <div className={cn("space-y-small", className)}>
      {selectIdentifiers.map((selectIdentifier) => {
        const selectAnswer = getExerciseAnswer(exerciseInstance, selectIdentifier)
        const selectComponent = getExerciseComponent(exerciseInstance, selectIdentifier)

        if (!selectComponent) {
          return null
        }

        if (!selectAnswer) {
          return (
            <div key={selectIdentifier}>
              <h4>{selectComponent?.sentence}: Not selected</h4>
            </div>
          )
        }

        const selectAnswerLabel =
          selectComponent?.options?.find((option) => option.value === selectAnswer)?.label ?? null
        const maxCount = maxListCounts[selectAnswer] ?? 0
        const selectedAnswerList = answerGroups[selectAnswer] ?? []
        const slicedAnswerList = selectedAnswerList.slice(0, maxCount)

        // Remove used answers from the list
        if (answerGroups[selectAnswer]) {
          answerGroups[selectAnswer] = selectedAnswerList.slice(maxCount)
        }

        return (
          <div key={selectIdentifier}>
            <h4>
              {selectComponent?.sentence}: {selectAnswerLabel}
            </h4>
            <BulletedList className={className}>
              {slicedAnswerList.map((answer, idx) => (
                <li key={`${selectIdentifier}_${idx}`}>{answer}</li>
              ))}
            </BulletedList>
          </div>
        )
      })}
    </div>
  )
}

export default RepeatingGroupTextFieldMatchingListGroups
