import cn from "classnames"
import { useField } from "formik"
import { isEqual, shuffle, sortBy } from "lodash-es"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { styled } from "styled-components"

import { AngleDownIcon, AngleUpIcon } from "icons/FontAwesomeIcons"
import ReorderIcon from "icons/ReorderIcon"
import Button from "ui/Button"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import Loading from "ui/Loading"
import View from "ui/View"

const ExerciseRankOrderField = styled(function ExerciseRankOrderField({
  saveOnChange,
  name,
  options,
  repeatingGroupFieldId = null,
  repeatingGroupTextFieldId = null,
  emptyMessage = "No options available",
  className,
}) {
  const [field, _meta, { setValue }] = useField(name)
  const [repeatingGroupField] = useField(repeatingGroupFieldId)
  const hasRepeatingGroupOptions = repeatingGroupFieldId && repeatingGroupTextFieldId
  const repeatingGroupOptions = ((hasRepeatingGroupOptions && repeatingGroupField?.value) ?? [])
    .map((option) => {
      const textItem = option[repeatingGroupTextFieldId] ?? null
      const textValue = textItem?.value ?? textItem
      if (!textValue) {
        return null
      }
      return { value: textValue, label: textValue }
    })
    .filter(Boolean)
  const hasValue = hasRepeatingGroupOptions ? !!field.value : !!field.value?.length

  useEffectAfterFirstRender(() => {
    if (!hasValue) {
      const initialOptions = hasRepeatingGroupOptions ? repeatingGroupOptions : shuffle(options)
      const currentValue = initialOptions.map((rankItem) => rankItem.value)
      setValue(currentValue)
      saveOnChange(name, currentValue)
    } else if (repeatingGroupOptions?.length) {
      const fieldValues = field.value
      const missingValues = repeatingGroupOptions.map((option) => option.value)
      const validValues = []
      // We loop through fieldValues rather than comparing two sets
      // to allow for duplicate values
      fieldValues.forEach((value) => {
        const index = missingValues.indexOf(value)
        if (index !== -1) {
          validValues.push(value)
          missingValues.splice(index, 1) // remove value at index
        }
      })

      const newValues = [...validValues, ...missingValues]
      if (!isEqual(fieldValues, newValues)) {
        setValue(newValues)
        saveOnChange(name, newValues)
      }
    }
  })

  if (!hasValue) {
    return <Loading />
  }

  let sortedOptions
  if (hasRepeatingGroupOptions) {
    sortedOptions = []
    // Create a draggableId for based on option order to ensure
    // that the index is maintained when dragging
    const newOptions = repeatingGroupOptions.map((option, index) => ({
      ...option,
      draggableId: `${option.value}-${index}`,
    }))
    const fieldValues = field.value
    fieldValues.forEach((value) => {
      const optionIndex = newOptions.findIndex((option) => option.value === value)
      if (optionIndex !== -1) {
        sortedOptions.push(newOptions[optionIndex])
        newOptions.splice(optionIndex, 1)
      }
    })
  } else {
    sortedOptions = sortBy(options, [(o) => field.value.indexOf(o.value)])
  }

  function handleOnDragEnd(result) {
    if (!result.destination) {
      return
    }
    reOrder(result.source.index, result.destination.index)
  }

  const handleOnUp = (index) => {
    if (index === 0) {
      return
    }
    reOrder(index, index - 1)
  }

  const handleOnDown = (index) => {
    if (index === sortedOptions.length - 1) {
      return
    }
    reOrder(index, index + 1)
  }

  function reOrder(currentIndex, newIndex) {
    const rankedItems = Array.from(sortedOptions)
    const [reorderedItem] = rankedItems.splice(currentIndex, 1)
    rankedItems.splice(newIndex, 0, reorderedItem)

    const newOrder = rankedItems.map((rankItem) => rankItem.value)
    setValue(newOrder)
    saveOnChange(name, newOrder)
  }

  if (!sortedOptions.length) {
    return <div className={cn("text-italic text-gray-8", className)}>{emptyMessage}</div>
  }

  return (
    <View className={cn("py-large", className)}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="rank-order-field-container">
              {sortedOptions.map(({ label, value, draggableId = null }, index) => (
                <Draggable key={draggableId ?? value} draggableId={draggableId ?? value} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="ranking-items border-top border-bottom--last py-medium"
                    >
                      <div className="number-ranking mr-medium">{index + 1}</div>
                      <View className="mr-small">{label}</View>
                      <View $width="auto" $alignItems="center">
                        <View $flexDirection="column" $width="auto">
                          <Button className="link" onClick={() => handleOnUp(index)}>
                            <AngleUpIcon className="text-gray-8" />
                          </Button>
                          <Button className="link" onClick={() => handleOnDown(index)}>
                            <AngleDownIcon className="text-gray-8" />
                          </Button>
                        </View>
                        <View $width="auto" className="ml-small">
                          <ReorderIcon />
                        </View>
                      </View>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </View>
  )
})`
  .ranking-items {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .link {
    margin: 0;
    padding: 0;
    height: auto;
  }

  .number-ranking {
    color: var(--rising-orange);
  }

  .rank-order-field-container {
    width: 100%;
    background-color: #fff;
  }
`

export default ExerciseRankOrderField
