import { Widget } from "@typeform/embed-react"
import cn from "classnames"
import { styled } from "styled-components"

import Header from "components/Header"
import RTKitViewBase from "components/RTKitViewBase"
import { useBenchmarkSurveyInfo } from "resources/teams"
import { useUser } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"

const RT4SBenchmarkSurvey = styled(function RT4SBenchmarkSurvey({ className }) {
  const { data: currentUser } = useUser({ userId: "me" })
  const { token, uid, tid } = useQueryParams()
  const { data: surveyInfo, isFetching: infoIsFetching } = useBenchmarkSurveyInfo({ token, uid, tid })

  if (!currentUser || infoIsFetching || !surveyInfo) {
    return null
  }

  const hiddenFields = {
    user_id: currentUser?.id,
    account_id: surveyInfo?.account_id,
  }

  return (
    <div className={cn(className, "survey-container")}>
      <Header />
      <RTKitViewBase>
        <div className="main-container full-width">
          <h1 className="mb-small">Benchmark Survey</h1>
          <Widget id="tpVvcsN8" hidden={hiddenFields} className="typeform-survey" />
        </div>
      </RTKitViewBase>
    </div>
  )
})`
  .typeform-survey {
    width: 100%;
    height: 1000px;
  }
`
export default RT4SBenchmarkSurvey
