import { useQuery, type QueryClient, useMutation } from "@tanstack/react-query"

import api, { fetchApiPost } from "api"
import { ArtiHistoryOrder, ArtiSender, DEFAULT_ARTI_HISTORY_ORDER } from "domains/Arti/constants"
import { addShortNameFieldToTeamMembers } from "resources/users"
import { checkNamedArguments } from "utils/function"
import { buildUrl } from "utils/string"

const cacheKeys = {
  teams: ["rtai", "teams"],
  historyTopExchanges(
    teamId: TeamID | null = null,
    chatMemberId: UserID | null = null,
    order: ArtiHistoryOrder | null = null,
    query: string | null = null
  ): Array<string | Record<string, string | number>> {
    const base = ["rtai", "history", "exchange", "top"]
    if (teamId || chatMemberId || order || query) {
      return [
        ...base,
        {
          ...(teamId ? { teamId } : {}),
          ...(chatMemberId ? { chatMemberId } : {}),
          ...(order ? { order } : {}),
          ...(query ? { query } : {}),
        },
      ]
    } else {
      return base
    }
  },
  historySessionExchanges(exchangeId: ArtiExchangeID): Array<string | ArtiExchangeID> {
    return ["rtai", "history", "exchange", exchangeId, "session"]
  },
}

function getArtiTeams() {
  return async () => {
    const url = buildUrl(["rtai", "teams"])
    const { data } = await api.get(url)
    return data.map((team: TeamData) => addShortNameFieldToTeamMembers({ team }))
  }
}

function useArtiTeams({ enabled = true }: { enabled?: boolean } = {}) {
  checkNamedArguments("useArtiTeams", arguments, { optional: ["enabled"] })
  return useQuery(cacheKeys.teams, getArtiTeams(), { enabled })
}

async function artiTeamMemberChatStreaming(
  teamId: TeamID,
  chatMemberId: UserID,
  message: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
    reviewType = null,
    performanceReviewOptions = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "member_chat_stream"])
  return fetchApiPost(url, {
    member_id: chatMemberId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
    review_type: reviewType,
    performance_review_options: performanceReviewOptions,
  })
}

async function artiTeamMemberChatNonStreaming(
  teamId: TeamID,
  chatMemberId: UserID,
  message: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
    reviewType = null,
    performanceReviewOptions = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "member_chat_stream"])
  const { data } = await api.post(url, {
    member_id: chatMemberId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
    review_type: reviewType,
    performance_review_options: performanceReviewOptions,
  })
  return data
}

async function artiTeamChatStreaming(
  teamId: TeamID,
  message: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "team_chat_stream"])
  return fetchApiPost(url, {
    team_id: teamId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
  })
}

async function artiTeamChatNonStreaming(
  teamId: TeamID,
  message: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "team_chat_stream"])
  const { data } = await api.post(url, {
    team_id: teamId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
  })
  return data
}

async function artiUploadChatStreaming(
  teamId: TeamID,
  selectedMemberId: UserID,
  message: string,
  fileName: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
    reviewType = null,
    performanceReviewOptions = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "performance_review_upload_chat_stream"])
  return await fetchApiPost(url, {
    team_id: teamId,
    member_id: selectedMemberId,
    filename: fileName,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
    review_type: reviewType,
    performance_review_options: performanceReviewOptions,
  })
}

async function artiUploadChatNonStreaming(
  teamId: TeamID,
  selectedMemberId: UserID,
  message: string,
  fileName: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "performance_review_upload_chat_stream"])
  const { data } = await api.post(url, {
    team_id: teamId,
    member_id: selectedMemberId,
    filename: fileName,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
  })
  return data
}

async function artiPerformanceReviewStreaming(
  teamId: TeamID,
  chatMemberId: UserID,
  message: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
    reviewType = null,
    performanceReviewOptions = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "performance_review_chat_stream"])
  return fetchApiPost(url, {
    member_id: chatMemberId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
    review_type: reviewType,
    performance_review_options: performanceReviewOptions,
  })
}

async function artiPerformanceReviewChatNonStreaming(
  teamId: TeamID,
  chatMemberId: UserID,
  message: string,
  {
    llm = null,
    sessionStartedAt = null,
    startExchangeId = null,
    prevExchangeId = null,
    testId = null,
    chatTypeSelected = null,
    reviewType = null,
    performanceReviewOptions = null,
  } = {}
) {
  const url = buildUrl(["rtai", "team", teamId, "performance_review_chat_stream"])
  const { data } = await api.post(url, {
    member_id: chatMemberId,
    message,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    test_id: testId,
    chat_type: chatTypeSelected,
    review_type: reviewType,
    performance_review_options: performanceReviewOptions,
  })
  return data
}

async function preprocessArtiMemberData(teamId: TeamID, chatMemberId: UserID) {
  const url = buildUrl(["rtai", "team", teamId, "preprocess_member_data"])
  await api.post(url, { member_id: chatMemberId })
}

async function preprocessArtiTeamData(chatTeamId: TeamID) {
  const url = buildUrl(["rtai", "team", chatTeamId, "preprocess_team_data"], {
    urlQueryParams: { team_id: chatTeamId },
  })
  await api.post(url)
}

async function updateArtiExchangeRating(exchangeId: ArtiExchangeID, values: { rating: number }) {
  const url = buildUrl(["rtai", "history", "exchange", exchangeId, "chat_rating"])
  await api.post(url, values)
}

async function updateArtiExchangeInappropriateFlag(exchangeId: ArtiExchangeID, flagValue: boolean) {
  const url = buildUrl(["rtai", "history", "exchange", exchangeId, "chat_inappropriate_flag"])
  await api.post(url, { inappropriate_flag: flagValue })
}

async function updateExcludeFromManualReview(exchangeId: ArtiExchangeID, excludeFromManualReview: boolean) {
  const url = buildUrl(["rtai", "history", "exchange", exchangeId, "update_exclude_from_manual_review"])
  await api.post(url, { exclude_from_manual_review: excludeFromManualReview })
}

function getArtiHistoryTopExchanges({
  teamId = null,
  chatMemberId = null,
  order = DEFAULT_ARTI_HISTORY_ORDER,
  query = null,
}: { teamId?: TeamID | null; chatMemberId?: UserID | null; order?: string; query?: string | null } = {}) {
  return async () => {
    const url = buildUrl(["rtai", "history", "exchange", "top"], {
      urlQueryParams: {
        order,
        ...(query ? { query } : {}),
        ...(teamId ? { teamId } : {}),
        ...(chatMemberId ? { chatMemberId } : {}),
      },
    })
    const { data } = await api.get(url)
    const { exchanges } = data
    return exchanges
  }
}

function useArtiHistoryTopExchanges({
  teamId = null,
  chatMemberId = null,
  order = DEFAULT_ARTI_HISTORY_ORDER,
  query = null,
  enabled = true,
} = {}) {
  return useQuery(
    cacheKeys.historyTopExchanges(teamId, chatMemberId, order, query),
    getArtiHistoryTopExchanges({ teamId, chatMemberId, order, query }),
    { enabled: !!enabled }
  )
}

function getArtiHistorySessionExchanges(exchangeId: ArtiExchangeID) {
  return async () => {
    const url = buildUrl(["rtai", "history", "exchange", exchangeId, "session"])
    const { data } = await api.get(url)
    const { exchanges } = data
    return exchanges
  }
}

function useArtiHistorySessionExchanges(exchangeId: ArtiExchangeID, { enabled }: { enabled?: boolean } = {}) {
  return useQuery(cacheKeys.historySessionExchanges(exchangeId), getArtiHistorySessionExchanges(exchangeId), {
    enabled: !!enabled && !!exchangeId,
  })
}

function prependHistorySessionExchangeToQueryCache(
  queryClient: QueryClient,
  exchange: ArtiExchangeData,
  teamId: TeamID | null = null,
  chatMemberId: UserID | null = null
): void {
  const cacheKey = cacheKeys.historyTopExchanges(teamId, chatMemberId)
  const cachedList = queryClient.getQueryData(cacheKey, { exact: false })
  const existingList = Array.isArray(cachedList) ? (cachedList as ArtiExchangeData[]) : []
  const updatedList = [exchange, ...existingList]
  queryClient.setQueriesData(cacheKey, updatedList)
}

function getHistorySession(sessionExchanges: Array<ArtiExchangeData> | null) {
  const [firstExchange] = sessionExchanges ?? []
  const [lastExchange] = (sessionExchanges ?? []).slice(-1)
  return {
    llm: lastExchange?.llm_type ?? null,
    teamId: lastExchange?.team ?? null,
    chatMemberId: lastExchange?.member ?? null,
    isTeamExchange: lastExchange?.is_team_exchange ?? false,
    chatType: lastExchange?.chat_type ?? null,
    reviewType: lastExchange?.review_type ?? null,
    performanceReviewOptions: lastExchange?.performance_review_options ?? null,
    firstExchangeId: firstExchange?.id ?? null,
    lastExchangeId: lastExchange?.id ?? null,
    excludeFromManualReview: firstExchange?.exclude_from_manual_review ?? false,
    messages: (sessionExchanges ?? []).flatMap((exchange) => [
      {
        sender: ArtiSender.USER,
        text: exchange.query,
        helpfulnessRating: exchange.helpfulness_rating,
        accuracyRating: exchange.accuracy_rating,
        feedback: exchange.feedback,
        inappropriateFlag: exchange.inappropriate_flag,
        exchangeId: exchange.id,
      },
      {
        sender: ArtiSender.BOT,
        text: exchange.response,
        helpfulnessRating: exchange.helpfulness_rating,
        accuracyRating: exchange.accuracy_rating,
        feedback: exchange.feedback,
        inappropriateFlag: exchange.inappropriate_flag,
        exchangeId: exchange.id,
      },
    ]),
  }
}

const sendAndProcessArtiChat = async ({
  team,
  selectedChatMember,
  newUserMessage,
  updateNewBotMessage,
  useStreaming,
  artiChatParams,
}: {
  team: TeamData
  selectedChatMember: UserData
  newUserMessage: string
  updateNewBotMessage: (message: { text: string; exchangeId: ArtiExchangeID }) => void
  useStreaming: boolean
  artiChatParams: Record<string, any>
}) => {
  if (useStreaming) {
    const artiResponse = await artiTeamMemberChatStreaming(
      team.id,
      selectedChatMember.id,
      newUserMessage,
      artiChatParams
    )
    return await processArtiResponseStreaming({ artiResponse, updateNewBotMessage })
  }

  const artiResponse = await artiTeamMemberChatNonStreaming(
    team.id,
    selectedChatMember.id,
    newUserMessage,
    artiChatParams
  )
  return processArtiResponseNonStreaming({ artiResponse, updateNewBotMessage })
}

const sendAndProcessTeamArtiChat = async ({
  team,
  newUserMessage,
  updateNewBotMessage,
  useStreaming,
  artiChatParams,
}: {
  team: TeamData
  newUserMessage: string
  updateNewBotMessage: (message: { text: string; exchangeId: ArtiExchangeID }) => void
  useStreaming: boolean
  artiChatParams: Record<string, any>
}) => {
  if (useStreaming) {
    const artiResponse = await artiTeamChatStreaming(team.id, newUserMessage, artiChatParams)
    return await processArtiResponseStreaming({ artiResponse, updateNewBotMessage })
  }

  const artiResponse = await artiTeamChatNonStreaming(team.id, newUserMessage, artiChatParams)
  return processArtiResponseNonStreaming({ artiResponse, updateNewBotMessage })
}

const sendAndProcessArtiUpload = async ({
  team,
  newUserMessage,
  selectedChatMember,
  fileName,
  updateNewBotMessage,
  useStreaming,
  artiChatParams,
}: {
  team: TeamData
  newUserMessage: string
  selectedChatMember: UserData
  fileName: string
  updateNewBotMessage: (message: { text: string; exchangeId: ArtiExchangeID }) => void
  useStreaming: boolean
  artiChatParams: Record<string, any>
}) => {
  if (useStreaming) {
    const artiResponse = await artiUploadChatStreaming(
      team.id,
      selectedChatMember.id,
      newUserMessage,
      fileName,
      artiChatParams
    )
    return await processArtiResponseStreaming({ artiResponse, updateNewBotMessage })
  }

  const artiResponse = await artiUploadChatNonStreaming(
    team.id,
    selectedChatMember.id,
    newUserMessage,
    fileName,
    artiChatParams
  )
  return processArtiResponseNonStreaming({ artiResponse, updateNewBotMessage })
}

const sendAndProcessPerformanceReviewChat = async ({
  team,
  selectedChatMember,
  newUserMessage,
  updateNewBotMessage,
  useStreaming,
  artiChatParams,
}: {
  team: TeamData
  newUserMessage: string
  selectedChatMember: UserData
  fileName: string
  updateNewBotMessage: (message: { text: string; exchangeId: ArtiExchangeID }) => void
  useStreaming: boolean
  artiChatParams: Record<string, any>
}) => {
  if (useStreaming) {
    const artiResponse = await artiPerformanceReviewStreaming(
      team.id,
      selectedChatMember.id,
      newUserMessage,
      artiChatParams
    )
    return await processArtiResponseStreaming({ artiResponse, updateNewBotMessage })
  }

  const artiResponse = await artiPerformanceReviewChatNonStreaming(
    team.id,
    selectedChatMember.id,
    newUserMessage,
    artiChatParams
  )
  return processArtiResponseNonStreaming({ artiResponse, updateNewBotMessage })
}

// Read in arti response stream and update the bot message text in real time
// The first line of the response is a JSON object with arti metatdata
// The rest of the response is the bot message text
// The rest of the response is the bot message text
const processArtiResponseStreaming = async ({
  artiResponse,
  updateNewBotMessage,
}: {
  artiResponse: Record<string, any>
  updateNewBotMessage: (message: { text: string; exchangeId: ArtiExchangeID }) => void
}) => {
  const textBodyStream = artiResponse.body.pipeThrough(new TextDecoderStream())

  let artiMetadata = null
  let exchangeId = null
  let botMessageText = ""

  // Read in the response stream one chunk at a time and update the bot message text in real time
  for await (const chunk of textBodyStream) {
    const isFirstLineProcessed = !!artiMetadata

    if (!isFirstLineProcessed) {
      const newLineIndex = chunk.indexOf("\n")
      const foundEndOfFirstLine = newLineIndex >= 0

      if (foundEndOfFirstLine) {
        const firstLine = chunk.substring(0, newLineIndex)
        const remainingText = chunk.substring(newLineIndex + 1)
        artiMetadata = JSON.parse(firstLine)
        botMessageText = remainingText
        exchangeId = artiMetadata.arti_exchange?.id
        updateNewBotMessage({ text: botMessageText, exchangeId })
      }
    } else {
      botMessageText += chunk
      updateNewBotMessage({ text: botMessageText, exchangeId })
    }
  }

  return artiMetadata
}

const processArtiResponseNonStreaming = ({
  artiResponse,
  updateNewBotMessage,
}: {
  artiResponse: Record<string, any>
  updateNewBotMessage: (message: { text: string; exchangeId: ArtiExchangeID }) => void
}) => {
  const newLineIndex = artiResponse.indexOf("\n")
  const firstLine = artiResponse.substring(0, newLineIndex)
  const remainingText = artiResponse.substring(newLineIndex + 1)
  const artiMetadata = JSON.parse(firstLine)
  const botMessageText = remainingText
  const exchangeId = artiMetadata.arti_exchange?.id

  updateNewBotMessage({ text: botMessageText, exchangeId })

  return artiMetadata
}

async function processArtiTextExtraction({
  teamId,
  chatMemberId,
  jobId,
  llm = null,
  sessionStartedAt = null,
  startExchangeId = null,
  prevExchangeId = null,
  chatTypeSelected = null,
  reviewType = null,
  performanceReviewOptions = null,
}: {
  teamId: TeamID
  chatMemberId: UserID
  jobId: string | null
  llm?: string | null
  sessionStartedAt?: string | null
  startExchangeId?: ArtiExchangeID | null
  prevExchangeId?: ArtiExchangeID | null
  chatTypeSelected?: string | null
  reviewType?: string | null
  performanceReviewOptions?: string | null
}) {
  const url = buildUrl(["rtai", "team", teamId, "process_text_extraction"])
  const { data } = await api.post(url, {
    member_id: chatMemberId,
    job_id: jobId,
    llm,
    session_started_at: sessionStartedAt,
    start_exchange_id: startExchangeId,
    prev_exchange_id: prevExchangeId,
    chat_type: chatTypeSelected,
    review_type: reviewType,
    performance_review_options: performanceReviewOptions,
  })
  return data
}

function useProcessArtiTextExtraction({
  teamId,
  chatMemberId,
  llm,
  sessionStartedAt,
  startExchangeId,
  prevExchangeId,
  chatTypeSelected,
  reviewType,
  performanceReviewOptions,
  jobId = null,
  refetchInterval = false,
  enabled = false,
}: {
  textractJobId?: string | null
  teamId: TeamID
  chatMemberId: UserID
  jobId: string | null
  llm?: string | null
  sessionStartedAt?: string | null
  startExchangeId?: ArtiExchangeID | null
  prevExchangeId?: ArtiExchangeID | null
  chatTypeSelected?: string | null
  reviewType?: string | null
  performanceReviewOptions?: string | null
  refetchInterval?: number | false
  enabled?: boolean
}) {
  const data = useQuery(
    ["rtai", "textract", jobId],
    () =>
      processArtiTextExtraction({
        teamId,
        chatMemberId,
        jobId,
        llm,
        sessionStartedAt,
        startExchangeId,
        prevExchangeId,
        chatTypeSelected,
        reviewType,
        performanceReviewOptions,
      }),
    { refetchInterval, enabled }
  )
  return data
}

function startArtiTextExtraction({ teamId }: { teamId: TeamID }) {
  return async ({ filename }: { filename: string }) => {
    const url = buildUrl(["rtai", "team", teamId, "start_text_extraction"])
    const { data } = await api.post(url, { filename })
    return data
  }
}

function useStartArtiTextExtraction({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useStartArtiTextExtraction", arguments, { required: ["teamId"] })
  return useMutation(startArtiTextExtraction({ teamId }))
}

export {
  cacheKeys,
  useArtiTeams,
  artiTeamMemberChatStreaming,
  artiTeamMemberChatNonStreaming,
  artiTeamChatStreaming,
  artiTeamChatNonStreaming,
  preprocessArtiMemberData,
  preprocessArtiTeamData,
  updateArtiExchangeRating,
  updateArtiExchangeInappropriateFlag,
  updateExcludeFromManualReview,
  useArtiHistoryTopExchanges,
  useArtiHistorySessionExchanges,
  prependHistorySessionExchangeToQueryCache,
  getHistorySession,
  sendAndProcessArtiChat,
  sendAndProcessTeamArtiChat,
  sendAndProcessArtiUpload,
  sendAndProcessPerformanceReviewChat,
  useProcessArtiTextExtraction,
  useStartArtiTextExtraction,
}
