import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { getRefetchKitHomeKitsFunction } from "domains/LeaderKit/KitsTableofContents/utils"
import ScheduleForm from "domains/LeaderKit/ScheduleForm"
import { useUpdateOrCreateKitInstance, useStartKitSession } from "resources/monthly_kit"
import Button from "ui/Button"
import { colors } from "ui/theme"
import View from "ui/View"
import { KitType } from "utils/kit"

const AvailableKitExpandedContentRevamp = ({
  kitInstance,
  kitInfo,
  user,
  selectedTeam,
  className,
  isScheduleModeActive,
}) => {
  const queryClient = useQueryClient()
  const isMini = kitInfo.type === KitType.MINI
  const isCurrentTeamLead = selectedTeam.team_lead_id === user.id
  const isDemo = selectedTeam.demo

  const navigate = useNavigate()
  const { mutateAsync: startKitSession } = useStartKitSession()
  const { mutateAsync: createKitInstance } = useUpdateOrCreateKitInstance({
    slug: kitInfo.slug,
    teamId: selectedTeam.id,
  })

  const onSessionStartClick = async () => {
    if (!kitInstance) {
      kitInstance = await createKitInstance(kitInfo.slug, selectedTeam.id)
    }
    await startKitSession({ kitInstanceId: kitInstance.id })
    navigate(kitInstance.session_url)
  }

  const onPreviewSessionClick = async () => {
    if (!kitInstance) {
      kitInstance = await createKitInstance()
    }
    navigate(kitInstance?.session_preview_url)
  }

  return (
    <div className={className}>
      {!selectedTeam?.preview && (!!isScheduleModeActive || !!isCurrentTeamLead) && !isMini && (
        <View
          $alignItems="center"
          $alignItemsMobile="flex-start"
          $justifyContent={selectedTeam.preview ? "flex-end" : "space-between"}
          $flexDirectionMobile="column-reverse"
          className="mt-medium"
        >
          <ScheduleForm
            kitInstance={kitInstance}
            kitInfo={kitInfo}
            onAddToCalendar={getRefetchKitHomeKitsFunction(selectedTeam.id, queryClient)}
            team={selectedTeam}
            className="fit-content"
          />
        </View>
      )}
      {!selectedTeam?.preview && !!isCurrentTeamLead && !!isMini && (
        <View $alignItems="center">
          <Button className="tertiary p-medium" onClick={onSessionStartClick} data-testid={`kit-link:${kitInfo.slug}`}>
            Start
          </Button>
          {(!!isCurrentTeamLead || !!isDemo) && (
            <Button
              color={colors.risingBlue}
              className="link inline-block text-normal mb-xxs"
              onClick={onPreviewSessionClick}
            >
              Preview
            </Button>
          )}
        </View>
      )}
    </div>
  )
}

export default AvailableKitExpandedContentRevamp
