import cn from "classnames"
import { keyBy } from "lodash-es"
import { styled } from "styled-components"

import getIconOrError from "icons"
import useWindowSize from "ui/hooks/useWindowSize"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const RadioGroupMultiUserOptionList = ({
  identifier,
  exerciseInstances,
  exerciseComponent,
  sortedUsers,
  horizontal,
  showLabelWithIcon,
  columns = 4,
  activeStateColor = "var(--orange-2)",
  emptyStateColor = "var(--orange-2)",
  showMobileVertical = false,
  showFullSizeVersion = false,
  useChoiceColors = false,
  reverseOrder = false,
}) => {
  const { isMobileOrSmaller } = useWindowSize()
  const choiceOptions = reverseOrder ? [...exerciseComponent.options].reverse() : exerciseComponent.options
  const displayChoiceOptions = showMobileVertical && isMobileOrSmaller ? [...choiceOptions].reverse() : choiceOptions
  const userIdMap = keyBy(sortedUsers, "id")

  const OptionList =
    showMobileVertical && isMobileOrSmaller
      ? VerticalOptionList
      : horizontal
        ? HorizontalOptionList
        : VerticalOptionList

  return (
    <OptionList
      identifier={identifier}
      exerciseInstances={exerciseInstances}
      displayChoiceOptions={displayChoiceOptions}
      userIdMap={userIdMap}
      showLabelWithIcon={showLabelWithIcon}
      columns={columns}
      activeStateColor={activeStateColor}
      emptyStateColor={emptyStateColor}
      showFullSizeVersion={showFullSizeVersion}
      useChoiceColors={useChoiceColors}
    />
  )
}

const VerticalOptionList = ({
  identifier,
  exerciseInstances,
  displayChoiceOptions,
  userIdMap,
  activeStateColor,
  emptyStateColor,
  showFullSizeVersion,
  useChoiceColors,
  showLabelWithIcon,
}) => (
  <div className="mb-xl">
    {displayChoiceOptions.map((choiceOption) => {
      const userNames = userNamesForAnswer(exerciseInstances, userIdMap, identifier, choiceOption.value)
      const Icon = choiceOption.icon && getIconOrError(choiceOption.icon)

      if (Icon) {
        return (
          <VerticalAnswerDisplayIcon
            key={choiceOption.value}
            title={choiceOption.short_label}
            icon={Icon}
            text={userNames}
            color={choiceOption.color}
            activeStateColor={activeStateColor}
            emptyStateColor={emptyStateColor}
            showFullSizeVersion={showFullSizeVersion}
            useChoiceColors={useChoiceColors}
            showLabelWithIcon={showLabelWithIcon}
            className="mb-small"
          />
        )
      } else {
        return (
          <VerticalAnswerDisplayLarge
            key={choiceOption.value}
            title={choiceOption.short_label}
            description={userNames}
            className="mb-small"
          />
        )
      }
    })}
  </div>
)

const VerticalAnswerDisplayIcon = styled(function VerticalAnswerDisplayIcon({
  title,
  icon,
  text,
  color,
  activeStateColor,
  emptyStateColor,
  showFullSizeVersion,
  useChoiceColors,
  showLabelWithIcon,
  className,
}) {
  const isMobileOrSmaller = useWindowSize().isMobileOrSmaller
  const Icon = icon
  const activeColor = color && useChoiceColors ? color : activeStateColor
  const emptyColor = emptyStateColor ?? activeColor

  if (showLabelWithIcon) {
    return (
      <View
        className={cn("border border-radius blur-4", { "border-light": showFullSizeVersion }, className)}
        $alignItems="stretch"
        $flexDirection={isMobileOrSmaller ? "column" : "row"}
      >
        <View className="icon-with-label ml-small">
          <Icon
            aria-label={title}
            aria-hidden={true}
            width="1em"
            className="fa-2x"
            color={!!text ? activeColor : emptyColor}
          />
          <div className="ml-small text-field-label">{title}</div>
        </View>
        <View $alignItems="center" className="description">
          <div className={cn("text-small", { "px-small py-xs": showFullSizeVersion })}>{text}</div>
        </View>
      </View>
    )
  }

  return (
    <View
      className={cn("border border-radius blur-4", { "border-light": showFullSizeVersion }, className)}
      $alignItems="stretch"
    >
      <Tooltip
        top
        float
        title={title}
        className={cn("icon-no-label border-gray-5", { "border-right": showFullSizeVersion })}
      >
        <Icon aria-label={title} aria-hidden={false} className="fa-2x" color={!!text ? activeColor : emptyColor} />
      </Tooltip>
      <View $alignItems="center" className="description">
        <div className={cn("text-small ml-small", { "px-small py-xs": showFullSizeVersion })}>{text}</div>
      </View>
    </View>
  )
})`
  min-height: ${({ showFullSizeVersion }) => (showFullSizeVersion ? "60px" : "50px")};

  .icon-no-label {
    align-items: center;
    justify-content: center;
    flex-basis: 65px;
  }
  .icon-with-label {
    align-items: center;
    flex-basis: 300px;
    padding-top: 8px;
  }
  .description {
    flex: 1;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .icon-with-label {
      flex-basis: fit-content;
    }
  }
`

const VerticalAnswerDisplayLarge = styled(function VerticalAnswerDisplayLarge({ title, description, className }) {
  return (
    <View className={cn("border border-gray-4 border-radius", className)} $alignItems="stretch">
      <View $alignItems="center" $justifyContent="center" className="title">
        <div className="text-rising-orange text-normal text-center text-bold px-small py-xs">{title}</div>
      </View>
      <View $alignItems="center" $justifyContent="center" className="description">
        <div className="text-small text-gray-9 px-small py-xs">{description}</div>
      </View>
    </View>
  )
})`
  min-height: 60px;

  .title {
    width: 200px;
  }
  .description {
    flex: 1;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .title {
      width: 150px;
      justify-content: left;
    }
  }
`

const HorizontalOptionList = styled(function HorizontalOptionList({
  identifier,
  exerciseInstances,
  displayChoiceOptions,
  userIdMap,
  showLabelWithIcon,
  className,
  activeStateColor,
  emptyStateColor,
  useChoiceColors,
}) {
  return (
    <View className={cn("mb-xl", className)} $alignItems="stretch" $gap="var(--spacing-2)" $flexWrap="wrap">
      {displayChoiceOptions.map((choiceOption) => {
        const { value, icon, short_label, color } = choiceOption
        const userNames = userNamesForAnswer(exerciseInstances, userIdMap, identifier, value)
        const Icon = icon && getIconOrError(icon)

        return (
          <View
            key={value}
            $flexDirection="column"
            $alignItems="center"
            $gap="8px"
            className="option-box border border-gray-4 border-radius-small p-medium"
          >
            {Icon ? (
              <>
                <Tooltip top float title={short_label} className="title-tooltip">
                  <Icon
                    aria-label={short_label}
                    aria-hidden={!!showLabelWithIcon}
                    className="fa-2x"
                    color={!!(color && useChoiceColors) ? color : userNames ? activeStateColor : emptyStateColor}
                    height={32}
                    width={32}
                  />
                </Tooltip>
                {!!showLabelWithIcon && <div className="text-field-label text-center">{short_label}</div>}
              </>
            ) : (
              <div className="title text-rising-orange text-utility text-bold text-center">{short_label}</div>
            )}
            <div className="text-utility text-center">{userNames}</div>
          </View>
        )
      })}
    </View>
  )
})`
  .option-box {
    max-width: 192px;
  }
  .title-tooltip {
    justify-content: center;
  }

  width: 100%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 176px));
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    grid-template-columns: repeat(${({ columns }) => Math.min(columns, 4)}, minmax(0, 1fr));
  }
  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: repeat(${({ columns }) => Math.min(columns, 3)}, minmax(0, 1fr));
  }
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: repeat(${({ columns }) => Math.min(columns, 2)}, minmax(0, 1fr));
  }
`

const userNamesForAnswer = (exerciseInstances, userIdMap, identifier, value) => {
  const matches = exerciseInstances.filter((instance) =>
    instance.answers.find((answer) => {
      if (answer.identifier !== identifier) {
        return false
      }
      const answerValue = answer.data.value
      if (answerValue === value) {
        return true
      }
      if (Array.isArray(answerValue) && answerValue.includes(value)) {
        return true
      }
      return false
    })
  )

  const users = matches.map((instance) => userIdMap[instance.user_id]).filter((u) => !!u)
  return users
    .map((u) => u.short_name)
    .sort()
    .join(", ")
}

export default RadioGroupMultiUserOptionList
