import cn from "classnames"
import { sortBy } from "lodash-es"

import { getExerciseAnswer } from "../results_utils"

import BulletedList from "ui/BulletedList"

const RepeatingGroupTextFieldMatchingList = ({
  identifier,
  textIdentifier,
  matchValues = {},
  noMatchMessage = "No matching items",
  exerciseInstance,
  className,
}) => {
  const answerList = (getExerciseAnswer(exerciseInstance, identifier) ?? []).filter((answer) => {
    const textAnswer = answer[textIdentifier]
    const textValue = textAnswer?.value ?? textAnswer
    if (!textValue) {
      return false
    }

    return Object.entries(matchValues).every(([matchIdentifier, matchValueList = []]) => {
      const matchAnswer = answer[matchIdentifier]
      const matchValue = matchAnswer?.value ?? matchAnswer
      return matchValueList.includes(matchValue)
    })
  })

  // If a match list has multiple possible values, sort giving priority to
  // matches that are earlier in the list
  const sortedAnswerList = sortBy(answerList, (answer) => {
    const indexTotal = Object.entries(matchValues).reduce((sum, [matchIdentifier, matchValueList = []]) => {
      const matchAnswer = answer[matchIdentifier]
      const matchValue = matchAnswer?.value ?? matchAnswer
      return sum + (matchValueList.indexOf(matchValue) || 0)
    }, 0)

    return indexTotal
  })

  if (sortedAnswerList.length <= 0) {
    return <p className={cn("text-italic text-gray-8", className)}>{noMatchMessage}</p>
  }

  return (
    <BulletedList className={className}>
      {sortedAnswerList.map((answer, idx) => (
        <li key={`${identifier}_${textIdentifier}_${idx}`}>{answer[textIdentifier].value}</li>
      ))}
    </BulletedList>
  )
}

export default RepeatingGroupTextFieldMatchingList
