import { styled } from "styled-components"

import { getPerformanceOptionsSelectedMessage } from "../utils"

import View from "ui/View"

const ArtiPerformanceReviewOptionsSelector = styled(function ArtiPerformanceReviewOptionsSelector({
  user,
  teamMember,
  performanceReviewOptions,
}) {
  return (
    <View $flexDirection="column" className="flex-shrink-0">
      <View $flexDirection="column">
        <div className="mb-xxs text-semi-bold">
          <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
          <span className="ml-xxs">aRTi</span>
        </div>
        <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
          <div>{getPerformanceOptionsSelectedMessage({ teamMember, user, performanceReviewOptions })}</div>
        </View>
      </View>
    </View>
  )
})`
  width: 100%;
`

export default ArtiPerformanceReviewOptionsSelector
