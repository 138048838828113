import cn from "classnames"
import { Form, Formik } from "formik"
import { range } from "lodash-es"
import { useState, useMemo, useCallback } from "react"
import { styled } from "styled-components"

import SearchInput from "components/SearchInput"
import { getKitStatus, sortKits } from "domains/LeaderKit/KitsTableofContents/utils"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { SquareIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"
import { KitGoal } from "utils/kit"

const KitFiltersRevamp = ({
  basicKits,
  unavailableKits,
  initialVisibleKitsCount,
  kitInstanceMap,
  visibleKits,
  setVisibleKits,
  setVisibleKitsCount,
  onDisplayCloseMatchSearchMessageChange = null,
}) => {
  const [minLengthFilter, setMinLengthFilter] = useState(15)
  const [maxLengthFilter, setMaxLengthFilter] = useState(180)
  const [statusFilter, setStatusFilter] = useState("available")
  const [typeFilter, setTypeFilter] = useState("all")
  const [goalsFilter, setGoalsFilter] = useState({
    [KitGoal.BUILD_CONNECTION]: false,
    [KitGoal.SKILL_DEVELOPMENT]: false,
    [KitGoal.TEAM_INSIGHTS]: false,
    [KitGoal.HAVE_FUN]: false,
  })
  const [searchQuery, setSearchQuery] = useState([])
  const [progress, setProgress] = useState(100)
  const [isLoadingComplete, setIsLoadingComplete] = useState(false)
  const { isTabletOrLarger } = useWindowSize()

  const initialValues = {
    min_length: 15,
    max_length: 180,
    status: "available",
    type: "all",
  }

  const unavailableStandardKits = unavailableKits.map((kit) => ({ ...kit, unavailable: true }))
  const kitTotal = `(${visibleKits.length} of ${basicKits.length + unavailableStandardKits.length})`

  const matchesSearchQuery = ({ kit, searchQuery }) =>
    searchQuery.some((term) => kit.title.toLowerCase().includes(term) || kit.description.toLowerCase().includes(term))

  const getFilteredKits = useCallback(
    ({ filterByStatus = true } = {}) => {
      const unavailableStandardKits = unavailableKits.map((kit) => ({ ...kit, unavailable: true }))
      const listOfKits =
        statusFilter === "all" || statusFilter === "available" ? [...basicKits, ...unavailableStandardKits] : basicKits

      const filteredKits = listOfKits.filter((kit) => {
        if (minLengthFilter && kit.rounded_session_time_minutes < minLengthFilter) {
          return false
        }

        if (maxLengthFilter && kit.rounded_session_time_minutes > maxLengthFilter) {
          return false
        }

        if (filterByStatus) {
          const kitInstance = kitInstanceMap[kit.slug]
          const kitStatus = getKitStatus(kitInstance, kit)
          if (statusFilter !== "all" && statusFilter !== "available" && kitStatus !== statusFilter) {
            return false
          }
          if (
            statusFilter === "available" &&
            kitStatus !== "available" &&
            kitStatus !== "unavailable" &&
            kitStatus !== "scheduled"
          ) {
            return false
          }
        }

        if (typeFilter !== "all" && kit.type !== typeFilter) {
          return false
        }

        const activeGoalsFilter = Object.keys(goalsFilter).filter((key) => goalsFilter[key])
        const activeGoalsAndKitGoalsMatches = activeGoalsFilter.filter((goalFilter) => kit.goals.includes(goalFilter))
        const goalFiltersMatches = activeGoalsAndKitGoalsMatches.length > 0
        if (activeGoalsFilter.length && !goalFiltersMatches) {
          return false
        }

        if (!!searchQuery.length && !matchesSearchQuery({ kit, searchQuery })) {
          return false
        }

        return true
      })

      const activeFilters = Object.keys(goalsFilter).filter((key) => goalsFilter[key])
      if (!activeFilters.length) {
        return filteredKits
      }

      // Sort items based on goal matching with closest matches first
      const sortedKits = [...filteredKits].sort((a, b) => {
        const aExactMatch =
          activeFilters.every((goal) => a.goals.includes(goal)) && a.goals.length === activeFilters.length
        const bExactMatch =
          activeFilters.every((goal) => b.goals.includes(goal)) && b.goals.length === activeFilters.length

        if (aExactMatch && !bExactMatch) {
          return -1
        }
        if (!aExactMatch && bExactMatch) {
          return 1
        }

        const aMatches = activeFilters.filter((goal) => a.goals.includes(goal)).length
        const bMatches = activeFilters.filter((goal) => b.goals.includes(goal)).length

        if (bMatches !== aMatches) {
          return bMatches - aMatches
        }

        return a.goals.length - b.goals.length
      })

      return sortedKits
    },
    [
      basicKits,
      unavailableKits,
      kitInstanceMap,
      minLengthFilter,
      maxLengthFilter,
      statusFilter,
      typeFilter,
      goalsFilter,
      searchQuery,
    ]
  )

  useEffectAfterChange(() => {
    setProgress(100)
    setIsLoadingComplete(false)

    const filteredKits = getFilteredKits()
    const nonFilteredSearchKits = !!searchQuery.length
      ? basicKits.filter((kit) => matchesSearchQuery({ kit, searchQuery }))
      : []
    const newVisibleKits = !!filteredKits.length ? filteredKits : nonFilteredSearchKits
    const activeFilters = Object.keys(goalsFilter).filter((key) => goalsFilter[key])

    if (!activeFilters.length && (statusFilter === "all" || statusFilter === "available")) {
      setVisibleKits(sortKits(newVisibleKits, kitInstanceMap))
    } else {
      setVisibleKits(newVisibleKits)
    }

    setVisibleKitsCount(initialVisibleKitsCount)

    // Display close match message if there are no filter matches but there are non filtered search matches
    if (!filteredKits.length && !!nonFilteredSearchKits.length) {
      onDisplayCloseMatchSearchMessageChange?.(true)
    } else {
      onDisplayCloseMatchSearchMessageChange?.(false)
    }
  }, [
    getFilteredKits,
    searchQuery,
    basicKits,
    initialVisibleKitsCount,
    setVisibleKits,
    setVisibleKitsCount,
    kitInstanceMap,
    searchQuery.length,
    onDisplayCloseMatchSearchMessageChange,
    goalsFilter,
    statusFilter,
  ])

  const unfilteredStatusFilteredKits = useMemo(() => getFilteredKits({ filterByStatus: false }), [getFilteredKits])

  const statusKitCountMap = useMemo(
    () => ({
      all: unfilteredStatusFilteredKits.length,
      available: unfilteredStatusFilteredKits.filter(
        (kit) =>
          getKitStatus(kitInstanceMap[kit.slug], kit) === "available" ||
          getKitStatus(kitInstanceMap[kit.slug], kit) === "unavailable" ||
          getKitStatus(kitInstanceMap[kit.slug], kit) === "scheduled"
      ).length,
      in_progress: unfilteredStatusFilteredKits.filter(
        (kit) => getKitStatus(kitInstanceMap[kit.slug], kit) === "in_progress"
      ).length,
      scheduled: unfilteredStatusFilteredKits.filter(
        (kit) => getKitStatus(kitInstanceMap[kit.slug], kit) === "scheduled"
      ).length,
      complete: unfilteredStatusFilteredKits.filter((kit) => getKitStatus(kitInstanceMap[kit.slug], kit) === "complete")
        .length,
    }),
    [unfilteredStatusFilteredKits, kitInstanceMap]
  )

  // array of numbers starting at 15 and incrementing by 15 up to 180
  const lengthMin = 15
  const lengthMax = 180
  const lengthStep = 15
  const minLengthOptions = range(lengthMin, lengthMax + lengthStep, lengthStep)
  const maxLengthOptions = range(lengthMin, lengthMax + lengthStep, lengthStep)

  const statusOptions = [
    { value: "all", label: "All", count: statusKitCountMap["all"] },
    { value: "available", label: "Not started", count: statusKitCountMap["available"] },
    { value: "in_progress", label: "In progress", count: statusKitCountMap["in_progress"] },
    { value: "scheduled", label: "Scheduled", count: statusKitCountMap["scheduled"] },
    { value: "complete", label: "Completed", count: statusKitCountMap["complete"] },
  ]

  const typeOptions = [
    { value: "all", label: "All" },
    { value: "kit", label: "Development" },
    { value: "boost", label: "Connection" },
    { value: "mini", label: "Mini" },
  ]

  return (
    <>
      <Formik initialValues={initialValues}>
        <Form>
          <View $justifyContent="space-between" className="mb-medium">
            <h2>Kits {kitTotal}</h2>
            {!!isTabletOrLarger && (
              <SearchInput query={searchQuery} setQuery={setSearchQuery} placeholder="Search for a kit" />
            )}
          </View>
          <View $gap="20px" $flexWrap="wrap">
            <View $flexDirection="column" $width="auto">
              <div className="text-semi-bold mb-xs">Min length</div>
              <AdvancedSelectField
                name="min_length"
                onChange={({ value }) => setMinLengthFilter(value)}
                options={minLengthOptions.map((option) => ({
                  label: `${option} minutes`,
                  value: option,
                  isDisabled: option > maxLengthFilter,
                }))}
                width={134}
              />
            </View>
            <View $flexDirection="column" $width="auto">
              <div className="text-semi-bold mb-xs">Max length</div>
              <AdvancedSelectField
                name="max_length"
                onChange={({ value }) => setMaxLengthFilter(value)}
                options={maxLengthOptions.map((option) => ({
                  label: `${option} minutes`,
                  value: option,
                  isDisabled: option < minLengthFilter,
                }))}
                width={134}
              />
            </View>
            <View $flexDirection="column" $width="auto">
              <div className="text-semi-bold mb-xs">Status</div>
              <AdvancedSelectField
                name="status"
                onChange={({ value }) => setStatusFilter(value)}
                options={statusOptions}
                formatOptionLabel={(option) => `${option.label} (${option.count})`}
                key={`${statusFilter}-${statusKitCountMap[statusFilter]}`}
                width={166}
              />
            </View>
            {!!isTabletOrLarger && (
              <View $flexDirection="column" $width="auto">
                <div className="text-semi-bold mb-xs">Type</div>
                <AdvancedSelectField
                  name="type"
                  onChange={({ value }) => setTypeFilter(value)}
                  options={typeOptions}
                  width={155}
                />
              </View>
            )}
          </View>
          <View className="mt-medium text-gray-8" $alignItems="center" $flexWrap="wrap">
            <div className="text-small text-semi-bold mr-small">Goals:</div>
            <GoalPill
              goalType="Build connection"
              color="var(--orange-2)"
              tintColor="var(--orange-tint)"
              goal={KitGoal.BUILD_CONNECTION}
              goalsFilter={goalsFilter}
              setGoalsFilter={setGoalsFilter}
            />
            <GoalPill
              goalType="Skill development"
              color="var(--rising-green)"
              tintColor="var(--green-tint)"
              goal={KitGoal.SKILL_DEVELOPMENT}
              goalsFilter={goalsFilter}
              setGoalsFilter={setGoalsFilter}
            />
            <GoalPill
              goalType="Team insights"
              color="var(--rising-yellow)"
              tintColor="var(--yellow-tint)"
              goal={KitGoal.TEAM_INSIGHTS}
              goalsFilter={goalsFilter}
              setGoalsFilter={setGoalsFilter}
            />
            <GoalPill
              goalType="Have fun"
              color="var(--orchid)"
              tintColor="var(--orchid-tint)"
              goal={KitGoal.HAVE_FUN}
              goalsFilter={goalsFilter}
              setGoalsFilter={setGoalsFilter}
            />
          </View>
        </Form>
      </Formik>
      <LoadingBar
        progress={progress}
        setProgress={setProgress}
        isLoadingComplete={isLoadingComplete}
        setIsLoadingComplete={setIsLoadingComplete}
      />
    </>
  )
}

const GoalPill = styled(function GoalPill({ className, goalType, color, goal, goalsFilter, setGoalsFilter }) {
  const { isTabletOrSmaller } = useWindowSize()

  const toggleFilter = (goal) => {
    setGoalsFilter((prevGoalsFilter) => {
      const newGoalsFilter = { ...prevGoalsFilter, [goal]: !prevGoalsFilter[goal] }
      return newGoalsFilter
    })
  }

  return (
    <div className={cn(className, "mr-xs", { "mb-xs": isTabletOrSmaller })}>
      <Button className="goals-pill tertiary blur-4" onClick={() => toggleFilter(goal)}>
        <SquareIcon className="rotated-icon m-none" color={goalsFilter[goal] ? color : "var(--gray-6)"} />
        <span className={cn("text-thin", goalsFilter[goal] ? "text-gray-9" : "text-gray-8")}>{goalType}</span>
      </Button>
    </div>
  )
})`
  .rotated-icon {
    transform: rotate(45deg);
    width: 12.86px;
    height: 11.89px;
  }

  .goals-pill {
    border-radius: var(--border-radius-large);
    border-color: ${({ color, goal, goalsFilter }) => (goalsFilter[goal] ? color : "transparent")};
    background-color: ${({ tintColor, goal, goalsFilter }) => (goalsFilter[goal] ? tintColor : "transparent")};
    font-size: 13px;
    padding: 6px 12px;
  }
`

const LoadingBar = styled(function LoadingBar({ className, setProgress, isLoadingComplete, setIsLoadingComplete }) {
  useEffectAfterChange(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev <= 0) {
          clearInterval(interval)
          setIsLoadingComplete(true)
          return 0
        }
        return prev - 5 // Decrease the progress by 5% every interval
      })
    }, 50) // Decrease every 50ms (faster progress)

    return () => clearInterval(interval)
  }, [setProgress, isLoadingComplete, setIsLoadingComplete])

  return (
    <div className={cn(className, "mt-small loading-bar-container")}>
      <div className="loading-bar-container">
        <div className="loading-bar" />
      </div>
    </div>
  )
})`
  .loading-bar-container {
    width: 100%;
    height: 2px;
    background-color: transparent;
    border-radius: 2px;
    overflow: hidden;
  }

  .loading-bar {
    height: 100%;
    width: 100%;
    margin-left: ${({ progress }) => `${100 - progress}%`};
    background: linear-gradient(90deg, #fb6211 15.7%, #f97830 39.27%, #f8d313 84.83%);
    transition: margin-left 0.05s linear;
    border-radius: 2px;
  }
`

export default KitFiltersRevamp
