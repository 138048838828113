import cn from "classnames"
import { isEmpty } from "lodash-es"

import { getSortedTruncatedUserAnswers } from "../results_utils"
import AnswerDisplaySmall from "../ResultsComponents/AnswerDisplaySmall"

const TextFieldMultiUserList = ({
  identifier,
  exerciseInstances,
  sortedUsers,
  className,
  maxNumResults = null,
  hideEmptyAnswers = false,
}) => {
  const sortedTruncatedUserAnswers = getSortedTruncatedUserAnswers(
    exerciseInstances,
    sortedUsers,
    identifier,
    maxNumResults
  ).filter(([_, answer]) => !hideEmptyAnswers || !isEmpty(answer))

  if (sortedTruncatedUserAnswers.length === 0) {
    return <p className={cn(className, "text-gray-8 text-italic mb-xl")}>No responses</p>
  }

  return (
    <div className={cn(className, "mb-xl")}>
      {sortedTruncatedUserAnswers.map(([user, answer]) => (
        <AnswerDisplaySmall key={user.id} className="mb-small" title={user.short_name} answer={answer} />
      ))}
    </div>
  )
}

export default TextFieldMultiUserList
