import * as Sentry from "@sentry/browser"
import { useFormikContext } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import { PERFORMANCE_REVIEW_OPTIONS } from "../constants"
import { useProcessArtiTextExtraction, useStartArtiTextExtraction } from "../resource"

import { fetchS3Post } from "api"
import { UploadIcon } from "icons/FontAwesomeIcons"
import { getAWSRtaiUploadURL } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import Loading from "ui/Loading"
import View from "ui/View"
import { getImageExtension } from "utils/image"

const MAX_IMAGE_SIZE_MB = 8

const ArtiUpload = styled(function ArtiUpload({
  onSubmit,
  onPerformanceReviewOptionsChange,
  isUploading,
  setIsUploading,
  llm,
  sessionStartedAt,
  startExchangeId,
  prevExchangeId,
  chatTypeSelected,
  reviewType,
  team,
  selectedChatMember,
}) {
  const formik = useFormikContext()

  const [textractJobId, setTextractJobId] = useState(null)

  const { data: textractJobStatus } = useProcessArtiTextExtraction({
    teamId: team?.id,
    chatMemberId: selectedChatMember.id,
    jobId: textractJobId,
    llm,
    sessionStartedAt,
    startExchangeId,
    prevExchangeId,
    chatTypeSelected,
    reviewType,
    performanceReviewOptions: PERFORMANCE_REVIEW_OPTIONS.UPLOAD_REVIEW,
    refetchInterval: textractJobId ? 1000 : false,
    enabled: !!textractJobId,
  })

  const { mutateAsync: startArtiTextExtraction } = useStartArtiTextExtraction({ teamId: team?.id })

  useEffectAfterChange(() => {
    if (!!textractJobStatus && textractJobStatus["status"] === "SUCCEEDED") {
      onPerformanceReviewOptionsChange({ value: PERFORMANCE_REVIEW_OPTIONS.UPLOAD_REVIEW })
      setTextractJobId(null)
      setIsUploading(false)
      onSubmit(
        {
          message: "Use the provided information for the review.",
          performanceReviewOptions: PERFORMANCE_REVIEW_OPTIONS.UPLOAD_REVIEW,
        },
        formik
      )
    }
  }, [textractJobStatus, onSubmit, formik, onPerformanceReviewOptionsChange, setIsUploading])

  async function onUpload(evt) {
    const uploadFile = evt.target.files[0]
    if (!uploadFile) {
      return
    }

    setIsUploading(true)

    const fileType = getImageExtension({ file: uploadFile })

    if (uploadFile.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      //   helpers.setError(`Image size must be under ${MAX_IMAGE_SIZE_MB}mb`)
      setIsUploading(false)
      return
    }

    const onSuccess = async ({ objectKeyAWS }) => {
      const response = await startArtiTextExtraction({ filename: objectKeyAWS })
      setTextractJobId(response["job_id"])
    }
    const onError = ({ error }) => {
      setIsUploading(false)
      Sentry.captureException(error)
    }
    const { aws_presigned_data: presignedUploadData, object_key: objectKeyAWS } = await getAWSRtaiUploadURL({
      userId: "me",
      fileType,
    })
    fetchS3Post(presignedUploadData, uploadFile)
      .then((response) => {
        if (response.ok) {
          onSuccess({ objectKeyAWS })
        } else {
          onError({ error: response })
        }
      })
      .catch((error) => {
        onError({ error })
      })
  }

  if (isUploading) {
    return <Loading inline />
  }

  return (
    <View className="arti-upload-button" $alignItems="center">
      <UploadIcon className="mr-small" />
      <label htmlFor="image-upload" className="width-max-content">
        Upload their self-review
      </label>
      <input id="image-upload" type="file" accept=".pdf, image/*" onChange={onUpload} />
    </View>
  )
})`
  .arti-upload-button {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;

    &:hover,
    &:focus-visible {
      border-radius: 4px;
      background-color: #fbe8ca;
      box-shadow: 0px 2px 4px 0px rgba(255, 211, 89, 0.75);
    }
  }
`

// const getAWSUploadErrorMessage = () => "An error occurred while uploading the file. Please upload again."

export default ArtiUpload
