import { styled } from "styled-components"

import ArtiChatTypeSelectField from "./ArtiChatTypeSelectField"

import View from "ui/View"

const ArtiChatTypeSelectorMessage = styled(function ArtiChatTypeSelectorMessage({
  className,
  chatTypeSelected,
  onChange,
  onStartNewConversation,
  user,
  showPerformanceReview,
  showTeamSummary,
}) {
  return (
    <View $flexDirection="column" className="flex-shrink-0">
      <div className="text-right mb-xxs text-semi-bold full-width">you</div>
      <div className="mb-medium pr-medium py-small message user full-width">
        <ArtiChatTypeSelectField
          className={className}
          onChange={onChange}
          chatTypeSelected={chatTypeSelected}
          onStartNewConversation={onStartNewConversation}
          user={user}
          showPerformanceReview={showPerformanceReview}
          showTeamSummary={showTeamSummary}
        />
      </div>
    </View>
  )
})``

export default ArtiChatTypeSelectorMessage
