import { styled } from "styled-components"

import { CHAT_TYPE } from "../constants"

import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { formatTimestampAsDate } from "utils/date"

const ArtiPreviousConversationsSelect = styled(function ArtiPreviousConversationsSelect({
  className,
  name,
  placeholder,
  onSelect,
  formik,
  historyExchanges,
  showTeamSummary,
  showPerformanceReview,
  showConversationName = false,
  teamMembers = null,
  teams = null,
  user = null,
  width = null,
  alignCenter = false,
  alignRight = false,
  isLoading = false,
  borderRadius = null,
  borderRadiusOpen = null,
}) {
  const teamMemberNameMap = new Map([
    ...(teamMembers?.map((member) => [member.id, member.short_name]) ?? []),
    ...(user ? [[user.id, "you"]] : []),
  ])
  const teamNameMap = new Map([...(teams?.map((team) => [team.id, team.name]) ?? [])])

  return (
    <AdvancedSelectField
      className={className}
      overflowHidden
      name={name}
      width={width}
      alignCenter={alignCenter}
      alignRight={alignRight}
      disabled={isLoading}
      placeholder={<span className="text-italic">{placeholder}</span>}
      borderRadius={borderRadius}
      borderRadiusOpen={borderRadiusOpen}
      saveOnChange={(_name, value) => onSelect({ value, formik })}
      options={(historyExchanges ?? [])
        .map(
          ({
            id,
            query,
            queried_at,
            member,
            team,
            is_team_exchange,
            chat_type,
            review_type,
            performance_review_options,
          }) => ({
            value: id,
            label: query,
            date: queried_at,
            memberName: !showConversationName ? null : (teamMemberNameMap.get(member) ?? "no one"),
            teamName: !showConversationName ? null : (teamNameMap.get(team) ?? "no particular team"),
            isTeamExchange: is_team_exchange,
            chatType: chat_type,
            reviewType: review_type,
            performanceReviewOptions: performance_review_options,
          })
        )
        .filter(
          ({ chatType, isTeamExchange }) =>
            (showTeamSummary && (chatType === CHAT_TYPE.TEAM || !!isTeamExchange)) ||
            (showPerformanceReview && chatType === CHAT_TYPE.PERFORMANCE_REVIEW) ||
            chatType === CHAT_TYPE.TEAM_MEMBER ||
            (!chatType && !isTeamExchange) // for backwards compatibility
        )}
      formatOptionLabel={({ label, date, memberName, teamName, isTeamExchange, chatType }) => (
        <>
          <span className="text-gray-6 mr-xs" title={formatTimestampAsDate(date, "PPPP 'at' p")}>
            {formatTimestampAsDate(date, "M/d/yy", {
              omitYearIfCurrent: true,
            })}
          </span>
          <span title={label.trim()}>{label.trim()}</span>
          {!!memberName && chatType === CHAT_TYPE.PERFORMANCE_REVIEW && (
            <span className="text-gray-6 history-member-name">about {memberName}'s Review</span>
          )}
          {!!teamName && (chatType === CHAT_TYPE.TEAM || !!isTeamExchange) && (
            <span className="text-gray-6 history-member-name">about team {teamName}</span>
          )}
          {!!memberName && (chatType === CHAT_TYPE.TEAM_MEMBER || (!chatType && !isTeamExchange)) && (
            <span className="text-gray-6 history-member-name">about {memberName}</span>
          )}
        </>
      )}
    />
  )
})`
  .history-member-name {
    float: right;
  }
`

export default ArtiPreviousConversationsSelect
