import { styled } from "styled-components"

import { CHAT_TYPE } from "../constants"
import { getChatTypeSelectedMessage } from "../utils"

import ArtiTeamSelectField from "./ArtiTeamSelectField"
import { ArtiTourStepNames } from "./ArtiTour"

import View from "ui/View"

const ArtiTeamSelectorMessage = styled(function ArtiTeamSelectorMessage({
  user,
  teams,
  llm,
  selectedTeam,
  chatTeamId,
  onChatTeamChange,
  showTeamSummary,
  chatTypeSelected,
}) {
  return (
    <View $flexDirection="column" className="flex-shrink-0">
      {!!showTeamSummary && chatTypeSelected === CHAT_TYPE.TEAM && (
        <View $flexDirection="column">
          <div className="mb-xxs text-semi-bold">
            <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
            <span className="ml-xxs">aRTi</span>
          </div>
          <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
            <div>{getChatTypeSelectedMessage({ user, llm, chatTypeSelected })}</div>
          </View>
        </View>
      )}
      <div className="text-right mb-xxs text-semi-bold full-width">you</div>
      <div
        className="mb-medium pr-medium py-small message user full-width"
        data-arti-tour-step={ArtiTourStepNames.MemberSelector}
      >
        <ArtiTeamSelectField
          user={user}
          teams={teams}
          selectedTeam={selectedTeam}
          chatTeamId={chatTeamId}
          onChatTeamChange={onChatTeamChange}
        />
      </div>
    </View>
  )
})`
  width: 100%;
`

export default ArtiTeamSelectorMessage
