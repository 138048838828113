import { styled } from "styled-components"

import { getChatTypeSelectedMessage } from "../utils"

import ArtiMemberSelectField from "./ArtiMemberSelectField"
import { ArtiTourStepNames } from "./ArtiTour"

import View from "ui/View"

const ArtiMemberSelectorMessage = styled(function ArtiMemberSelectorMessage({
  user,
  team,
  llm,
  selectedChatMember,
  teamMembers,
  chatMemberId,
  onChatMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
  showTeamSummary,
  showPerformanceReview,
  chatTypeSelected,
}) {
  return (
    <View $flexDirection="column" className="flex-shrink-0">
      {(!!showTeamSummary || !!showPerformanceReview) && (
        <View $flexDirection="column">
          <div className="mb-xxs text-semi-bold">
            <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
            <span className="ml-xxs">aRTi</span>
          </div>
          <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
            <div>{getChatTypeSelectedMessage({ user, llm, chatTypeSelected })}</div>
          </View>
        </View>
      )}
      <div className="text-right mb-xxs text-semi-bold full-width">you</div>
      <div
        className="mb-medium pr-medium py-small message user full-width"
        data-arti-tour-step={ArtiTourStepNames.MemberSelector}
      >
        <ArtiMemberSelectField
          user={user}
          team={team}
          selectedChatMember={selectedChatMember}
          teamMembers={teamMembers}
          chatMemberId={chatMemberId}
          onChatMemberChange={onChatMemberChange}
          memberIdTeamNameMap={memberIdTeamNameMap}
          onStartNewConversation={onStartNewConversation}
          showTeamSummary={showTeamSummary}
          chatTypeSelected={chatTypeSelected}
        />
      </div>
    </View>
  )
})`
  width: 100%;
`

export default ArtiMemberSelectorMessage
