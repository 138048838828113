import cn from "classnames"
import { Form, Formik } from "formik"
import { styled } from "styled-components"

import ArtiChoiceInput from "./ArtiChoiceInput"

import ChoicesField from "forms/fields/ChoicesField"
import { PlusIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import View from "ui/View"

const ArtiChatTypeSelectField = styled(function ArtiChatTypeSelectField({
  className,
  onChange,
  chatTypeSelected,
  onStartNewConversation,
  user,
  showPerformanceReview,
  showTeamSummary,
}) {
  const chatTypeSelectOptions = [
    { team_member: "Someone on my team" },
    // TODO (arti): restore once myself and something else options are working
    // { me: "Myself" },
    // { other: "Something else" },
    // TODO (perf-helper): restore once performance review helper is not feature flagged
    // { performance_review: "Performance review helper" },
  ]

  if (!!showTeamSummary) {
    chatTypeSelectOptions.push({ team: "My team" })
  }

  // TODO (perf-helper): remove this check once the perf-helper is not feature flagged
  if (!!showPerformanceReview && !user.is_demo_mode_active) {
    chatTypeSelectOptions.push({ performance_review: "Performance review helper" })
  }

  return (
    <Formik initialValues={{ chatType: chatTypeSelected }} enableReinitialize>
      <Form className={cn(className, "pl-small")}>
        <View $flexDirection="row" $flexWrap="wrap" $gap="var(--spacing-2)">
          <ChoicesField
            noContainer
            name="arti_conversation_type_select"
            type="radio"
            saveOnChange={(_name, value) => onChange({ value })}
            options={chatTypeSelectOptions.map((option) => ({
              value: Object.keys(option)[0],
              label: Object.values(option)[0],
            }))}
            component={ArtiChoiceInput}
            disabled={!!chatTypeSelected}
            childrenAfterChoices={
              !!chatTypeSelected && (
                <Button
                  className={cn("arti-select-new-conversation")}
                  onClick={onStartNewConversation}
                  data-testid="arti-select-new-conversation"
                >
                  <PlusIcon className="ml-small" />
                  New conversation
                </Button>
              )
            }
          />
        </View>
      </Form>
    </Formik>
  )
})`
  .arti-select-new-conversation {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;

    &:hover,
    &:focus-visible {
      border-radius: 4px;
      background-color: #fbe8ca;
      box-shadow: 0px 2px 4px 0px rgba(255, 211, 89, 0.75);
    }
  }
`

export default ArtiChatTypeSelectField
