import { styled } from "styled-components"

const PrioritizationTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>
        <p className="mb-medium">
          Prioritization is the art of deciding what matters most. It’s the difference between being busy and being
          effective. As Steve Jobs famously said, "Deciding what not to do is as important as deciding what to do." This
          insight forms the foundation of effective prioritization—focusing on tasks that drive the most impact while
          eliminating distractions.
        </p>
        <p className="mb-medium">The theme of this kit is prioritization methods for individual daily tasks.</p>
        <p className="mb-small">In this article, we’ll explore:</p>
        <ul>
          <li>Why prioritization is critical for team success</li>
          <li>Practical frameworks to help you and your team prioritize effectively</li>
          <li>Building a prioritization-focused culture</li>
        </ul>
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">Prioritization is critical to team success</h3>
        <div className="pr-medium">
          <p className="mb-medium">
            Success isn’t about doing more—it’s about focusing on what matters most. Effective prioritization channels
            time and energy into high-impact tasks, leading to better results, meaningful progress, and increased
            productivity (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mckinsey.com/featured-insights/mckinsey-explainers/what-is-productivity"
            >
              McKinsey Report
            </a>
            ).
          </p>
          <p>
            When we prioritize well, we optimize our time and energy in service of the outcomes we care about.
            Completing the same amount of tasks will yield even better and more meaningful total outcomes overall.
            Prioritization ensures that teams allocate their efforts where they matter most, driving both individual and
            collective performance (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mckinsey.com/featured-insights/mckinsey-explainers/what-is-productivity"
            >
              McKinsey Report
            </a>
            ).
          </p>
        </div>
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">Prioritization reduces burnout and promotes well-being</h3>
        <div className="pr-medium">
          <p className="mb-medium">
            Now more than ever, the ability to prioritize can mean the difference between thriving and feeling
            overwhelmed. Constant demands often divert attention to urgent but unimportant tasks. Prioritization
            provides clarity, helping teams focus on long-term goals and achieve meaningful outcomes.
          </p>
          <p className="mb-medium">
            Effective prioritization is also essential for managing workloads and reducing burnout. Employees who focus
            on meaningful tasks aligned with clear expectations and achievable goals experience less stress and greater
            job satisfaction. According to Gallup research, employees who strongly agree that they know what is expected
            of them are 47% less likely to experience frequent burnout.
          </p>
          <p className="mb-small">By focusing on prioritization, organizations can:</p>
          <p className="mb-medium">
            <ul>
              <li>Ensure workloads remain manageable</li>
              <li>Foster a healthier work environment</li>
              <li>Reduce stress and improve overall well-being</li>
            </ul>
          </p>
          <p className="mb-medium">
            Prioritization isn’t just a tool for improving efficiency; it’s a strategy for enhancing wellness and
            creating a supportive, empowered workplace.
          </p>
        </div>
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">How to prioritize: two practical frameworks</h3>
        <div className="pr-medium">
          <p className="mb-medium">
            Prioritization isn’t one-size-fits-all. Different methods work for different people and teams; the key is
            finding what works best for you and adjusting it as things change.
          </p>
          <p className="mb-medium">
            In this session, we will introduce two practical and proven prioritization strategies: the Eisenhower Matrix
            and the BGF (Boat Go Faster) Method.
          </p>
        </div>
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">1. The Eisenhower Matrix</h3>
        <div className="pr-medium">
          <p className="mb-medium">
            One of the most effective tools for prioritization is the Eisenhower Matrix, a framework popularized by
            President Dwight D. Eisenhower. His famous quote, “What is important is seldom urgent, and what is urgent is
            seldom important,” captures the essence of this method. The matrix helps people categorize tasks based on
            urgency and importance, enabling better decision-making.
          </p>
          <p className="mb-small">The Eisenhower Matrix divides tasks into four quadrants:</p>
          <p className="mb-medium">
            <ul>
              <li>
                <b>Quadrant 1: Urgent and Important</b> — Tasks that require immediate attention and have significant
                consequences if not addressed. Examples include crises and pressing deadlines.
              </li>
              <li>
                <b>Quadrant 2: Not Urgent but Important</b> — Tasks that contribute to long-term goals and personal or
                team development. These include strategic planning and relationship-building.
              </li>
              <li>
                <b>Quadrant 3: Urgent but Not Important</b> — Tasks that demand immediate attention but do not
                significantly impact long-term outcomes. Examples include interruptions or non-critical meetings.
              </li>
              <li>
                <b>Quadrant 4: Neither Urgent nor Important</b> — Tasks with little to no value often serve as
                distractions. These include excessive social media use or other time-wasting activities.
              </li>
            </ul>
          </p>
          <p className="mb-medium">
            <img
              src="https://static.risingteam.com/kit_assets/prioritization/eisenhower-training-v2.png"
              width="330"
              alt="Eisenhower Matrix"
            />
          </p>
          <h4 className="mb-small">Key benefits</h4>
          <p className="mb-medium">
            <ul>
              <li>Encourages focus on high-impact tasks</li>
              <li>Helps identify and minimize time-wasters</li>
              <li>Provides a clear visual framework for decision-making</li>
            </ul>
          </p>
          <p className="mb-medium">
            Pro tip: As new work comes up, revisit your tasks to ensure they’re in the right quadrant. Adjust as
            priorities shift.
          </p>
        </div>
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">2. The BGF (Boat Go Faster) Method</h3>

        <div className="pr-medium">
          <p className="mb-medium">
            The BGF Method originated in competitive rowing, where every action must make the boat go faster. Teams ask
            themselves, "Will this task make the boat go faster?"
          </p>
          <h4 className="mb-small">How it works:</h4>
          <p className="mb-medium">
            <ol>
              <li>
                <b>Identify your goal:</b> Clearly define what success looks like for your team, such as hitting a
                revenue target or delivering a project milestone.
              </li>
              <li>
                <b>Evaluate each task:</b> Before committing to a task, ask if it directly contributes to achieving the
                goal. If the answer is no, deprioritize or eliminate it.
              </li>
              <li>
                <b>Align on outcomes:</b> Ensure everyone understands how their work supports the larger goal.
              </li>
            </ol>
          </p>
          <h4 className="mb-small">Key benefits:</h4>
          <p className="mb-medium">
            <ul>
              <li>Drives focus on what truly matters</li>
              <li>Simplifies decision-making with a singular guiding principle</li>
              <li>Builds team alignment and accountability</li>
            </ul>
          </p>
          <p className="mb-medium">
            Pro tip: Pair this method with regular check-ins to keep everyone aligned on the "boat”—your ultimate team
            objective.
          </p>
        </div>
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">Building a prioritization-focused culture</h3>
        <div className="pr-medium">
          <p className="mb-medium">
            Every team and individual is different, and there’s no one-size-fits-all solution for prioritization.
            Experiment with these frameworks to find what works best for you personally and for your team’s unique goals
            and challenges.
          </p>
          <p className="mb-medium">
            Effective prioritization isn’t just a skill—it’s a process and a culture. By adopting tools like these,
            teams can reduce stress, improve focus, and achieve meaningful results faster.
          </p>
          <p className="mb-medium">
            Teams that embrace prioritization as a core value create an environment where everyone feels aligned,
            empowered, and successful. By using proven frameworks like the Eisenhower Matrix and consistently aligning
            actions with goals, teams can achieve meaningful results while maintaining balance and focus.
          </p>
        </div>
      </div>

      <div>
        <p className="my-medium text-semi-bold">Additional resources:</p>

        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.gallup.com/workplace/652769/despite-employer-prioritization-employee-wellbeing-falters.aspx"
            >
              Despite Employer Prioritization, Employee Wellbeing Falters
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://asana.com/resources/priority-matrix">
              Priority matrix: How to identify what matters and get more done
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/pulse/make-boat-go-faster-gavin-teehan/"
            >
              Will It Make The Boat Go Faster?
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.amazon.com/ONE-Thing-Surprisingly-Extraordinary-Results/dp/1885167776"
            >
              The One Thing, by Gary Keller and Jay Papasan
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://amazon.com/Do-What-Matters-Most-Prioritize/dp/1523092572"
            >
              Do What Matters Most, by Rob Shallenberger (Author), Steve Shallenberger
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://gettingthingsdone.com/">
              Getting Things Done, by David Allen
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://gregmckeown.com/books/essentialism/">
              Essentialism: The Disciplined Pursuit of Less
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default styled(PrioritizationTraining1)`
  ol,
  ul {
    margin: 0;
    padding-left: 24px;

    li {
      color: inherit;
    }
  }
  ol > li::marker {
    font-weight: 700;
  }
`
