import cn from "classnames"
import { styled } from "styled-components"

import { FishIcon } from "icons/FontAwesomeIcons"
import View from "ui/View"
import { KitType, getCustomKitTypeInfo } from "utils/kit"

const KitTypePillRevamp = styled(function KitTypePill({ className, selectedKitInfo }) {
  const kitType = selectedKitInfo?.type
  const customKitOrganization = selectedKitInfo?.custom_kit_organization
  const isMini = kitType === KitType.MINI

  if (!kitType || (!customKitOrganization && !isMini)) {
    return null
  }

  const kitTypeInfo = getCustomKitTypeInfo(customKitOrganization)
  const customPillLogo = kitTypeInfo?.customPillLogo
  const pillText = isMini ? "Mini" : kitTypeInfo.pillText
  const pillColorCss = isMini ? "text-gray-8" : kitTypeInfo.pillColorCss

  return (
    <View
      className={cn(className, "fit-content text-xs text-semi-bold", pillColorCss, {
        "border border-radius px-xs": !!customKitOrganization,
      })}
      $alignItems="center"
    >
      {!!customKitOrganization && <img src={customPillLogo} alt="custom-logo" className="xs-logo-icon mr-xs" />}
      {!!isMini && <FishIcon className="mini-fish-icon mr-xxs" />}
      <div className="text-nowrap">{pillText}</div>
    </View>
  )
})`
  ${({ isMini }) => !!isMini && "background: rgb(254 122 0 / 3%)"}

  .mini-fish-icon {
    height: 16px;
    width: 16px;
  }
`

export default KitTypePillRevamp
