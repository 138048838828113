// Note: Keep in sync with LLMType in backend/rtai/constants.py
enum LLMType {
  GPT_4o = "gpt-4o",
  GOOGLE_GEMINI_PRO_1_5 = "gemini-1.5-pro-latest",
  ANTHROPIC_CLAUDE_3_5_SONNET = "claude-3-5-sonnet-latest",
  LLAMA3_70B = "llama3-70b-8192",
  GPT_4 = "gpt-4",
  GPT_3_5 = "gpt-3.5-turbo",
  GPT_4o_VANILLA = "gpt-4o-vanilla",
  GOOGLE_GEMINI_PRO_1_5_VANILLA = "gemini-1.5-pro-vanilla",
  ANTHROPIC_CLAUDE_3_5_SONNET_VANILLA = "claude-3-5-sonnet-vanilla",
}

// Note: Keep in sync with LLMName in backend/rtai/constants.py
enum LLMName {
  GPT_4o = "GPT-4o",
  GOOGLE_GEMINI_PRO_1_5 = "Gemini Pro 1.5",
  ANTHROPIC_CLAUDE_3_5_SONNET = "Claude 3.5 Sonnet",
  LLAMA3_70B = "LLaMA3 70b",
  GPT_4 = "GPT-4",
  GPT_3_5 = "GPT-3.5",
  GPT_4o_VANILLA = "GPT-4o Vanilla",
  GOOGLE_GEMINI_PRO_1_5_VANILLA = "Gemini Pro 1.5 Vanilla",
  ANTHROPIC_CLAUDE_3_5_SONNET_VANILLA = "Claude 3.5 Sonnet Vanilla",
}

// Note: Keep in sync with VANILLA_LLM_TYPES in backend/rtai/constants.py
const VANILLA_LLM_TYPES = [
  LLMType.GPT_4o_VANILLA,
  LLMType.GOOGLE_GEMINI_PRO_1_5_VANILLA,
  LLMType.ANTHROPIC_CLAUDE_3_5_SONNET_VANILLA,
]

// Note: Keep in sync with ArtiSender in backend/rtai/constants.py
enum ArtiSender {
  USER = "user",
  BOT = "bot",
}

// Note: Keep in sync with ArtiHistoryOrder in backend/rtai/constants.py
enum ArtiHistoryOrder {
  FREQUENCY = "frequency",
  RECENCY = "recency",
  RATING = "rating",
}

const DEFAULT_ARTI_HISTORY_ORDER = ArtiHistoryOrder.RECENCY

const ARTI_RATING_NA_VALUE = -1

const MIN_RATEABLE_MESSAGE_LENGTH = 250

const CHAT_TYPE = {
  TEAM_MEMBER: "team_member",
  TEAM: "team",
  ME: "me",
  NO_ONE: "no_one",
  OTHER: "other",
  PERFORMANCE_REVIEW: "performance_review",
}

// Note: Keep in sync with REVIEW_TYPE in backend/rtai/constants.py
const REVIEW_TYPE = {
  SELF_REVIEW: "self_review",
  MGR_REVIEW: "mgr_review",
}

// Note: Keep in sync with PERFORMANCE_REVIEW_OPTIONS in backend/rtai/constants.py
const PERFORMANCE_REVIEW_OPTIONS = {
  UPLOAD_REVIEW: "upload_review",
  CHAT_REVIEW: "chat_review",
}

// Note: Keep in sync with NO_ONE_MEMBER in backend/rtai/constants.py
const NO_ONE_MEMBER = {
  id: 0 as UserID,
  short_name: "N/A",
  full_name: "No one in particular",
  pronouns: ["they", "them", "their", "theirs", "they/them/their"],
  first_name: "",
  last_name: "",
  name: "",
  email: "",
  is_registered: false,
  invited_at: null,
  onboarded_at: null,
  is_a_team_lead: false,
  is_staff: false,
}

export {
  LLMType,
  LLMName,
  VANILLA_LLM_TYPES,
  ArtiSender,
  ArtiHistoryOrder,
  DEFAULT_ARTI_HISTORY_ORDER,
  ARTI_RATING_NA_VALUE,
  MIN_RATEABLE_MESSAGE_LENGTH,
  CHAT_TYPE,
  NO_ONE_MEMBER,
  REVIEW_TYPE,
  PERFORMANCE_REVIEW_OPTIONS,
}
